import NoDataIcon from '@/src/assets/images/svg/no_reports.svg';

const NoReportsOnCurrentYear = (): JSX.Element => {
  return (
    <div className="flex flex-col h-full  items-center align-top">
      <div className="h-[72px] w-[72px] shadow-md rounded-[50%] mt-20 flex justify-center items-center">
        <img className="" src={NoDataIcon} alt="" />
      </div>
      <h2 className="text-interBlack text-xl font-medium py-2">
        No Reports on this year
      </h2>

      <span className="text-greyBlackColor text-sm font-medium text-center">
        There is no reports added
        <br /> by the user yet
      </span>
    </div>
  );
};

export default NoReportsOnCurrentYear;
