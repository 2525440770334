import React, { useEffect, useState } from 'react';
import BarChart, { BarChartData } from '@/components/Charts/BarChart/BarChart';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { USER_DETAILS } from '@/src/routes/routesConstants';
import usersSlice from '../../store/slice/users.slice';
import { useAppSelector } from '@/hooks/useRedux';
import { RootState } from '@/store/index';
import dayjs from 'dayjs';
import PerformanceChartLoader from './PerformanceChartLoader';
import { UserDetailsTypes } from '../../interface/users.interface';
import NoReports from './NoReports';
import DownArrowIcon from '@/src/assets/imagesComponents/DownArrowIcon';
import NoReportsOnCurrentYear from './NoReportsOnCurrentYear';

interface Props {
  userDetails: UserDetailsTypes | null;
}

const PerformanceChart: React.FC<Props> = ({ userDetails }) => {

  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(dayjs().year().toString());
  const [searchParams] = useSearchParams();
  const [barData, setBarData] = useState<BarChartData[]>([]);
  const id = searchParams.get('id');
  //const [barData, setBarData] = useState<BarChartDat[]>([]);
  const [error, setError] = useState<string | null>(null);

  const userPerformance = useAppSelector(
    (state: RootState) => state.users.userPerformance
  );
  const isUserPerformanceLoading = useAppSelector(
    (state: RootState) => state.users.isUserPerformanceLoading
  );

  useEffect(() => {
    if (id && selectedYear) {
      dispatch(
        usersSlice.actions.getUserPerformance({
          userId: id,
          year: selectedYear,
        })
      );
    }
  }, [window.location.host.includes(USER_DETAILS), selectedYear]);

  useEffect(() => {
    if (!userPerformance?.total_report_count) {
      setBarData([]);
    } else if (userPerformance?.report_count_data) {
      setBarData(
        userPerformance.report_count_data?.map(dataItem => {
          return {
            label: dataItem.month_name,
            value: Number(dataItem.count),
          };
        })
      );
    }
  }, [userPerformance]);

  const onYearChange = (year: string) => {
    // Check if the year is less than the joined date or greater than the current year

    const joinedYear = userDetails
      ? dayjs(userDetails.joined_at).year()
      : dayjs().year();
    const currentYear = dayjs().year();

    if (parseInt(year) < joinedYear) {
      setError('Enter a valid year.');
      return;
    }

    if (parseInt(year) > currentYear) {
      setError('Enter a valid year.');
      return;
    }
    // If no error, clear previous error and set the selected year
    setError(null);
    setSelectedYear(year);
  };
  return (
    <div className="bg-white rounded-lg flex-col shadow-md mt-11 text-[#AEB4C1] h-full calender-box pb-2">
      {isUserPerformanceLoading ? (
        <PerformanceChartLoader />
      ) : (
        <>
          {userDetails?.is_user_created_report ? (
            <>
              <div className="flex justify-between items-center p-6 border-b ">
                <span className=" text-lg font-[600] text-greyBlackColor">
                  Performance
                </span>
                <span className="flex items-center">
                  <span className="mr-3 text-[#AEB4C1] text-lg">
                    Total Reports:{' '}
                    <span className="font-semibold text-[#667085]">
                      {userPerformance?.total_report_count}
                    </span>
                  </span>
                  <div className="flex flex-col relative performance-calender">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          minDate={dayjs(
                            userDetails ? userDetails.joined_at : new Date()
                          )}
                          disableFuture
                          value={dayjs(`${selectedYear}-01-01`)}
                          views={['year']}
                          className={error ? 'errorBoxDatePicker' : ''}
                          onChange={(e: any) => {
                            if (dayjs(new Date(e)).isValid())
                              onYearChange(dayjs(new Date(e)).format('YYYY'));
                          }}
                        />
                        <div className="absolute right-3 top-4 cursor-pointer">
                          <DownArrowIcon />
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>

                    {error && (
                      <div className="text-redErrorColor text-xs w-[220px] absolute top-9">
                        {error}
                      </div>
                    )}
                  </div>
                </span>
              </div>
              {barData.length ? (
                <BarChart data={barData} />
              ) : (
                <div className=" flex justify-center items-center  ">
                  <NoReportsOnCurrentYear />
                </div>
              )}
            </>
          ) : (
            <div className=" h-[470px] flex justify-center items-center">
              <NoReports />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PerformanceChart;
