import Button from "@/components/Button";
import CloseIcon from '../../../../assets/images/svg/close_icon.svg';
import { useState } from "react";
import { useAppSelector } from "@/hooks/useRedux";
import Spinner from "@/components/Spinner";

interface EditProductConfirmProps {
    isOpen: boolean;
    handleIsOpen: (isOpen: boolean) => void;
    handleEditProducts: () => void
}

const EditProductConfirm: React.FC<EditProductConfirmProps> = ({ isOpen, handleIsOpen, handleEditProducts }) => {

    const [loading, setIsLoading] = useState(false)
    const isLosConfigurationsLoading = useAppSelector(
        state => state.settings.isLosConfigurationsLoading
    );

    return (
        <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[548px] font-Inter">
            <div className=" p-6 ">
                <div className="mt-3 text-left">
                    <img
                        src={CloseIcon}
                        alt="*"
                        className="cursor-pointer absolute right-5 top-8"
                        onClick={() => {
                            handleIsOpen(false)
                        }}
                    />
                    <h3
                        className="text-xl font-semibold leading-6 text-blackDarkColor"
                        id="modal-title"
                    >
                        Confirm Your Selection
                    </h3>
                    <div className="mt-4">
                        <p className="text-sm font-medium text-greyBlackLightColor leading-normal">
                            Some of the selected products may not be fully supported by our platform.
                            Do you want to proceed with saving these selections?
                        </p>
                    </div>
                </div>
            </div>
            <div className=" pt-3 pb-6 flex px-6 w-full">
                <button
                    type="button"
                    onClick={() => {
                        handleIsOpen(false)
                    }}
                    className="inline-flex justify-center items-center rounded h-9 border border-greyLightColor text-sm font-medium text-interBlack w-1/2"
                >
                    Cancel
                </button>
                <Button
                    type="button"
                    disabled={isLosConfigurationsLoading}
                    isLoading={loading}
                    onClick={() => {
                        handleEditProducts()
                        setIsLoading(!loading)
                    }}
                    text=""
                    className="inline-flex justify-center items-center h-9 rounded bg-green-600 text-sm font-medium text-white ml-3 w-1/2 hover:opacity-50"
                > {loading ? <Spinner /> : 'Save'}</Button>
            </div>
        </div>
    );
};

export default EditProductConfirm