
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import usersSlice from '../slice/users.slice';
import {
  AddOrganizationAdminContactInfoService,
  GetOrganizationUserRolesService,
  GetStateListService,
  GetUserPerformanceReports,
  GetUsersLimit,
  ListUsersService,
  OrganizationUserEditService,
  RemoveUserService,
  UserCreateOrganizationUserService,
  UserDetailsService,
} from '../../service/users.service';
import {
  EditOrgAdminProfileBody,
  EditUserBody,
  OrganizationUserBody,
  UserDetailsTypes,
  UserListBody,
  UserRoles,
  UsersListTypes,
} from '../../interface/users.interface';

import {
  addUserSuccessToastMessage,
  CONTACT_DETAILS_SAVED_SUCCESS_MESSAGE,
  PROFILE_DETAILS_EDITED_SUCCESS_MESSAGE,
  REMOVED_USER_SUCCESS_MSG,
  USERS_LIST_BODY_INITIAL_VALUES,
} from '@/lib/constants';
import { PendingPage } from '@/src/enums/enums';
import authSlice from '@/modules/Authentication/store/slice/auth.slice';
import settingSlice from '../../../Settings/store/slice/settings.slice';
import loaderSlice from '@/store/slices/loader.slice';
import { RootState } from '@/store/index';
import {
  AuthMeResponseTypes,
} from '@/modules/Authentication/interface/auth.interface';
import { GetUserReports, UserPerformanceResponse, UsersLimitResponse } from '../types/users.types';
import { StateListTypes, StatesListBodyTypes } from '@/modules/Customer/interface/customer.interface';


function* UsersListSaga(action: PayloadAction<UserListBody>) {
  try {
    yield put(usersSlice.actions.setIsLoading(true));
    const response: UsersListTypes = yield call(
      ListUsersService,
      action.payload
    );
    if (response) {
      yield put(usersSlice.actions.setUsersList(response));
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsLoading(false));
  }
}

function* RemoveUserSaga(
  action: PayloadAction<{
    userId: string;
    usersListBody: UserListBody | null;
    navigateFunction: () => void;
  }>
) {
  try {
    yield put(usersSlice.actions.setIsRemoveUserLoading(true));
    const response: AxiosResponse = yield call(
      RemoveUserService,
      action.payload.userId
    );
    if (response.status) {
      yield put(usersSlice.actions.setIsRemoveUserModalState(false));
      if (action.payload.usersListBody) {
        yield put(
          usersSlice.actions.getUsersList(
            action.payload.usersListBody as UserListBody
          )
        );
      } else {
        yield put(
          usersSlice.actions.getUsersList(USERS_LIST_BODY_INITIAL_VALUES)
        );
      }
      yield put(usersSlice.actions.getUsersLimit(action.payload));
      toast.success(REMOVED_USER_SUCCESS_MSG, { theme: 'colored' });
      action.payload.navigateFunction();
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsRemoveUserLoading(false));
  }
}

function* SaveContactDetailsSaga(
  action: PayloadAction<{
    country_code: string;
    phone_number: string;
  }>
) {
  try {
    yield put(usersSlice.actions.setIsUserRegistrationLoading(true));
    const response: AxiosResponse = yield call(
      AddOrganizationAdminContactInfoService,
      action.payload
    );
    const authMeData: AuthMeResponseTypes = yield select(
      (state: RootState) => state.auth.authMeData
    );
    if (response) {
      yield put(
        usersSlice.actions.setUserRegistrationPage(PendingPage.ADD_LOGO)
      );
      yield put(
        authSlice.actions.setAuthMe({
          ...authMeData,
          pending_page: PendingPage.ADD_LOGO,
        })
      );
      toast.success(CONTACT_DETAILS_SAVED_SUCCESS_MESSAGE, {
        theme: 'colored',
      });
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsUserRegistrationLoading(false));
  }
}

// function* SaveOrganizationDetailsSaga(
//   action: PayloadAction<{
//     body: {
//       nmls: string;
//       policy: string;
//       report_id: string;
//     };
//     navigate: NavigateFunction;
//   }>
// ) {
//   try {
//     yield put(usersSlice.actions.setIsUserRegistrationLoading(true));
//     const { body, navigate } = action.payload;
//     const response: AxiosResponse = yield call(
//       SaveOrganizationDetailsService,
//       body
//     );
//     const authMeData: AuthMeResponseTypes = yield select(
//       (state: RootState) => state.auth.authMeData
//     );
//     if (response) {
//       // Payment Required
//       const paymentScreenRequired = [
//         PaymentTypes.ORG_ADMIN_LOAN_OFFICER,
//         PaymentTypes.ORD_ADMIN,
//       ].includes(authMeData?.organization_payment_type_code!);

//       const pendingPage = paymentScreenRequired
//         ? PendingPage.ADD_SUBSCRIPTION_PLANS
//         : '';
//       yield put(usersSlice.actions.setUserRegistrationPage(pendingPage));
//       yield put(
//         authSlice.actions.setAuthMe({
//           ...authMeData,
//           pending_page: pendingPage,
//         })
//       );
//       toast.success(ORGANIZATION_DETAILS_SAVED_SUCCESS_MESSAGE, {
//         theme: 'colored',
//       });
//       if (!paymentScreenRequired) {
//         yield put(authSlice.actions.getAuthMe({ rememberMe: false }));
//         navigate(SETTINGS_LOS);
//       }
//     }
//   } catch (err) {
//     toast.error((err as AxiosError).message, { theme: 'colored' });
//   } finally {
//     yield put(usersSlice.actions.setIsUserRegistrationLoading(false));
//   }
// }

// function* GetSubscriptionPlanSaga() {
//   try {
//     yield put(
//       usersSlice.actions.setIsSelectPlanLoading({
//         isPlanSelected: null,
//         isPlansListLoading: true,
//       })
//     );
//     const response: SubscriptionPlansListTypes[] = yield call(
//       GetSubscriptionPlansListService
//     );
//     if (response) {
//       yield put(usersSlice.actions.setSubscriptionPlans(response));
//     }
//   } catch (err) {
//     toast.error((err as AxiosError).message, { theme: 'colored' });
//   } finally {
//     yield put(
//       usersSlice.actions.setIsSelectPlanLoading({
//         isPlanSelected: null,
//         isPlansListLoading: false,
//       })
//     );
//   }
// }

// function* SelectSubscriptionSaga(
//   action: PayloadAction<{
//     buttonIndex: number;
//     back_url: string;
//     plan_id: string;
//     is_optional_payment: boolean;
//   }>
// ) {
//   const { buttonIndex } = action.payload;
//   try {
//     yield put(
//       usersSlice.actions.setIsSelectPlanLoading({
//         isPlanSelected: buttonIndex,
//         isPlansListLoading: false,
//       })
//     );
//     const response: SelectSubscriptionResponse = yield call(
//       SelectSubscriptionPlanService,
//       action.payload
//     );
//     if (response.redirect_url) {
//       yield put(usersSlice.actions.setUserRegistrationPage(''));
//       toast.success(SUBSCRIPTION_PLAN_ACTIVE_SUCCESS_MSG, { theme: 'colored' });
//       window.location.href = response?.redirect_url;
//     }
//   } catch (err) {
//     toast.error((err as AxiosError).message, { theme: 'colored' });
//   } finally {
//     yield put(
//       usersSlice.actions.setIsSelectPlanLoading({
//         isPlanSelected: null,
//         isPlansListLoading: false,
//       })
//     );
//   }
// }

// function* getUserPerformance(action: PayloadAction<GetUserReports>) {
//   try {
//     yield put(usersSlice.actions.setIsUserPerformanceLoading(true));
//     const { year, userId } = action.payload;
//     const response: UserPerformanceResponse = yield GetUserPerformanceReports(
//       userId,
//       year
//     );
//     yield put(usersSlice.actions.setUserPerformance(response));
//   } catch (err) {
//     const error = (err as AxiosError).message;
//     toast.error(error, { theme: 'colored' });
//   } finally {
//     yield put(usersSlice.actions.setIsUserPerformanceLoading(false));
//   }
// }

function* getUsersLimit(action: PayloadAction<void>) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        usersSlice.actions.getUsersLimit.type
      )
    );

    const response: UsersLimitResponse = yield GetUsersLimit(action.payload);
    if (response)
      yield put(
        usersSlice.actions.setIsUsersLimitReached(
          !response.is_user_creation_allowed
        )
      );
  } catch (err) {
    const error = (err as AxiosError).message;
    toast.error(error, { theme: 'colored' });
  } finally {
    loaderSlice.actions.stopLoadingAction(
      usersSlice.actions.getUsersLimit.type
    );
  }
}

function* getUserRolesSaga() {
  try {
    yield put(usersSlice.actions.setIsUserRolesLoading(true));
    const response: UserRoles[] = yield GetOrganizationUserRolesService();
    if (response?.length) yield put(usersSlice.actions.setUserRoles(response));
  } catch (err) {
    const error = (err as AxiosError).message;
    toast.error(error, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsUserRolesLoading(false));
  }
}

function* GetStatesListSaga(action: PayloadAction<StatesListBodyTypes>) {
  try {
    yield put(usersSlice.actions.setIsStatesListLoading(true));
    const response: StateListTypes[] = yield call(
      GetStateListService,
      action.payload
    );
    if (response?.length) {
      yield put(usersSlice.actions.setStatesList(response));
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsStatesListLoading(false));
  }
}

function* AddUserSaga(
  action: PayloadAction<{
    usersListBody: UserListBody;
    addUserBody: OrganizationUserBody;
  }>
) {
  try {
    const { usersListBody, addUserBody } = action.payload;
    yield put(usersSlice.actions.setIsAddUserLoading(true));
    const response: number = yield call(
      UserCreateOrganizationUserService,
      addUserBody
    );
    if (response) {
      toast.success(addUserSuccessToastMessage, {
        theme: 'colored',
      });
      yield put(usersSlice.actions.getUsersList(usersListBody));
      yield put(usersSlice.actions.getUsersLimit(action.payload));
      yield put(usersSlice.actions.setStatesList([]));
      yield put(usersSlice.actions.setUserRoles([]));
      yield put(settingSlice.actions.setAddUserModalState(false));
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsAddUserLoading(false));
  }
}

function* UserDetailsSaga(
  action: PayloadAction<{
    userId: string;
  }>
) {
  try {
    const { userId } = action.payload;
    yield put(usersSlice.actions.setIsUserDetailsLoading(true));
    const response: UserDetailsTypes = yield call(UserDetailsService, userId);
    if (response) {
      yield put(usersSlice.actions.setUserDetails(response));
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsUserDetailsLoading(false));
  }
}

function* EditUserSaga(
  action: PayloadAction<{
    addUserBody: EditUserBody;
    userId: string;
  }>
) {
  try {
    const { addUserBody, userId } = action.payload;
    yield put(usersSlice.actions.setIsEditUserLoading(true));
    const response: number = yield call(
      OrganizationUserEditService,
      addUserBody
    );
    if (response) {
      yield put(usersSlice.actions.setStatesList([]));
      yield put(usersSlice.actions.setUserRoles([]));
      yield put(usersSlice.actions.setIsEditUserModalOpen(false));
      yield put(usersSlice.actions.getUserDetails({ userId: userId }));
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsEditUserLoading(false));
  }
}

function* EditOrganizationAdminProfileSaga(
  action: PayloadAction<EditOrgAdminProfileBody>
) {
  try {
    yield put(
      usersSlice.actions.setIsEditOrganizationAdminProfileLoading(true)
    );
    const response: number = yield call(
      OrganizationUserEditService,
      action.payload
    );
    if (response) {
      toast.success(PROFILE_DETAILS_EDITED_SUCCESS_MESSAGE, {
        theme: 'colored',
      });
      yield put(authSlice.actions.getAuthMe({ rememberMe: false }));
      yield put(
        settingSlice.actions.setIsEditProfileOpen({
          loanOfficer: false,
          organizationAdmin: false,
        })
      );
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      usersSlice.actions.setIsEditOrganizationAdminProfileLoading(false)
    );
  }
}


function* getUserPerformance(action: PayloadAction<GetUserReports>) {
  try {
    yield put(usersSlice.actions.setIsUserPerformanceLoading(true));
    const { year, userId } = action.payload;
    const response: UserPerformanceResponse = yield GetUserPerformanceReports(
      userId,
      year
    );
    yield put(usersSlice.actions.setUserPerformance(response));
  } catch (err) {
    const error = (err as AxiosError).message;
    toast.error(error, { theme: 'colored' });
  } finally {
    yield put(usersSlice.actions.setIsUserPerformanceLoading(false));
  }
}

export default function* userSaga() {
  yield takeEvery(usersSlice.actions.getUsersList.type, UsersListSaga);
  yield takeEvery(usersSlice.actions.removeUser.type, RemoveUserSaga);
  yield takeEvery(
    usersSlice.actions.saveContactDetails.type,
    SaveContactDetailsSaga
  );
  // yield takeEvery(
  //   usersSlice.actions.saveOrganizationDetails.type,
  //   SaveOrganizationDetailsSaga
  // );
  // yield takeEvery(
  //   usersSlice.actions.getSubscriptionPlans.type,
  //   GetSubscriptionPlanSaga
  // );
  // yield takeEvery(
  //   usersSlice.actions.selectSubscription.type,
  //   SelectSubscriptionSaga
  // );
  yield takeEvery(
    usersSlice.actions.getUserPerformance.type,
    getUserPerformance
  );
  yield takeEvery(usersSlice.actions.getUsersLimit.type, getUsersLimit);
  yield takeEvery(usersSlice.actions.getUserRoles.type, getUserRolesSaga);
  yield takeEvery(usersSlice.actions.getStatesList.type, GetStatesListSaga);
  yield takeEvery(usersSlice.actions.addUser.type, AddUserSaga);
  yield takeEvery(usersSlice.actions.getUserDetails.type, UserDetailsSaga);
  yield takeEvery(usersSlice.actions.editUser.type, EditUserSaga);
  yield takeEvery(
    usersSlice.actions.editOrganizationAdminProfile.type,
    EditOrganizationAdminProfileSaga
  );
}
