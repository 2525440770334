import { BASE_URL } from '@/lib/config';
import { ENDPOINTS } from '@/lib/constants';
import axiosInstance from '@/lib/interceptors';
import { AxiosError } from 'axios';
import {
  EditLoanOfficerProfileBodyTypes,
  EditOrgAdminProfileBody,
  EditProfileBody,
  OrganizationUserBody,
  UserDetailsTypes,
  UserListBody,
  UserRoles,
  UsersListTypes,
} from '../interface/users.interface';
import { ApiResponse } from '@/modules/Organizations/interface/organization.interface';
import { UserPerformanceResponse, UsersLimitResponse } from '../store/types/users.types';
import { StateListTypes } from '@/modules/Customer/interface/customer.interface';

export const UserCreateOrganizationUserService = async (
  body: OrganizationUserBody
) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/${body.organization_id}`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};


export const AddOrganizationAdminContactInfoService = async (body: {
  country_code: string;
  phone_number: string;
}) => {
  try {
    const response = await axiosInstance.patch(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.ADMIN}/contact-info`,
      body
    );
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetOrganizationUserRolesService = async (): Promise<
  UserRoles[]
> => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${ENDPOINTS.ROLES}`);
    return response?.data?.data as UserRoles[];
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ListUsersService = async (
  body: UserListBody
): Promise<UsersListTypes> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.ADMIN}/${ENDPOINTS.USERS}`,
      body
    );
    return response?.data?.data as UsersListTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ResendInviteService = async (body: { email: string }) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/invite/resend`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const InviteUserService = async (body: { email: string }) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/invite`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const UserDetailsService = async (
  id: string
): Promise<UserDetailsTypes> => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/${id}`
    );
    return response?.data?.data as UserDetailsTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const EditProfileService = async (body: EditProfileBody) => {
  try {
    const response = await axiosInstance.patch(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const generateCsvService = async (body?: {
  start_date?: string;
  end_date?: string;
}) => {
  try {
    const response = await axiosInstance.get<ApiResponse<null>>(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/generate-csv`,
      {
        params: body,
      }
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetUserPerformanceReports = async (
  userId: string,
  year: string
) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/${ENDPOINTS.REPORTS}/userPerformance/${userId}/${year}`
    );
    return response?.data?.data as UserPerformanceResponse;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetUsersLimit = async (id: any) => {
  try {
    let org_id
    if (typeof id === 'string') {
      org_id = id
    } else {
      org_id = id?.addUserBody?.organization_id
    }
    const response = await axiosInstance.get(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/organizationUserLimit/${org_id}`
    );
    return response?.data?.data as UsersLimitResponse;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const RemoveUserService = async (id: string) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/${id}`
    );
    return response;
  } catch (err) { }
};

export const GetStateListService = async (params: {
  sort: string;
  page: number;
  limit: number;
}): Promise<StateListTypes[]> => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/${ENDPOINTS.STATE}`, {
      params,
    });
    return response?.data?.data as StateListTypes[];
  } catch (err) {
    return [] as StateListTypes[];
  }
};

export const OrganizationUserEditService = async (
  body:
    | OrganizationUserBody
    | EditLoanOfficerProfileBodyTypes
    | EditOrgAdminProfileBody
    | {
      organization_user_id: string;
      phone_number: string;
    }
) => {
  try {
    const response = await axiosInstance.patch(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}`,
      body
    );
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const RemoveUserLogoService = async (id: string): Promise<{
  uploadURL: string;
  logo_url: string;
}> => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/logo/${id}`
    );
    return response?.data?.data as { uploadURL: string; logo_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};


export const GetPresignedUserURLService = async (body: {
  content_type: string;
  file_extension: string;
}, id: string) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/logo/${id}`,
      body
    );
    return response?.data?.data as { uploadURL: string; logo_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const SendPresignedUserURLService = async (body: { logo: string }, id: string) => {
  try {
    const response = await axiosInstance.patch(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.USER}/logo/${id}`,
      body
    );
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};