// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './BarChart.css';

export interface BarChartData {
  label: string;
  value: number;
}

interface Props {
  data: BarChartData[];
}

const BarChart: React.FC<Props> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const width = '100%'; // Adjust this as needed
    const height = 370; // Adjust this as needed
    const margin = { top: 40, right: 30, bottom: 50, left: 40 }; // Increased top margin for y-axis labels
    // Calculate inner dimensions based on the container width
    const containerWidth = svgRef.current.parentElement?.clientWidth || 0;
    const innerWidth = containerWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    svg.attr('width', width).attr('height', height);

    const xScale = d3
      .scaleBand()
      .domain(data.map(d => d.label))
      .range([0, innerWidth])
      .paddingInner(0.7)
      .paddingOuter(0.5);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .nice()
      .range([innerHeight, 0]);

    const xAxis = d3.axisBottom(xScale); // Create a bottom axis for x-axis labels
    const yAxis = d3.axisLeft(yScale).ticks(10);

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Clear existing bars and labels
    svg.selectAll('rect').remove();
    svg.selectAll('.bar-label').remove();
    svg.selectAll('.grid-line').remove();

    // Append horizontal grid lines
    g.selectAll('.grid-line')
      .data(yAxis.scale().ticks(10))
      .enter()
      .append('line')
      .attr('class', 'grid-line')
      .attr('x1', 0)
      .attr('x2', innerWidth)
      .attr('y1', d => yScale(d))
      .attr('y2', d => yScale(d))
      .attr('stroke', '#F5F5F5')
      .style('stroke', '2,2');

    // Append bars with animation
    g.selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.label) || 0)
      .attr('y', innerHeight) // Start bars at the bottom
      .attr('width', xScale.bandwidth())
      .attr('height', 0) // Initial height is 0
      .attr('fill', '#21BD7A')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('filter', 'drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2))')
      .transition()
      .duration(1000) // Animation duration in milliseconds
      .attr('y', d => yScale(d.value)) // Adjusted for proper bar growth
      .attr('height', d => innerHeight - yScale(d.value));

    // Append y-axis
    g.append('g').call(yAxis).attr('class', 'y-axis');

    // Append x-axis
    g.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(xAxis)
      .attr('class', 'x-axis')
      .selectAll('text')
      .style('text-anchor', 'middle')
      .attr('dy', '1.5em');

    // Display y-axis labels at the center of the top of bars
    g.selectAll('.bar-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'bar-label')
      .text(d => (d.value ? d.value : ''))
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .attr('fill', '#667085')
      .attr('x', d => xScale(d.label) + xScale.bandwidth() / 2 || 0)
      .attr('y', d => yScale(d.value) - 10)
      .attr('style', 'opacity:0')
      .transition()
      .duration(1000)
      .attr('style', 'opacity:1');
  }, [data]);

  return (
    <div className="bar-chart">
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default BarChart;
