import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';

import EditIcon from '@/src/assets/imagesComponents/EditIcon';
import { useAppSelector } from '@/hooks/useRedux';
import settingSlice from '../../store/slice/settings.slice';
import LosSettingsPageLoader from '../Components/LosSettingsPageLoader';
import { useParams } from 'react-router';
import { ProductListEntity } from '../../interface/settings.interface';
import UserLimitModel from '@/modules/Users/pages/Users/UserLimit';
import { RootState } from '@/store/index';
import { UserListBody } from '@/modules/Users/interface/users.interface';
import { LIMIT } from '@/lib/constants';
import usersSlice from '@/modules/Users/store/slice/users.slice';
import useDebounce from '@/hooks/useDebounce';
import Skeleton from 'react-loading-skeleton';
import TableRowHeader from '@/components/Table';
import NoUsersFound from '@/modules/Users/pages/Users/NewUsersFound';
import Button from '@/components/Button';
import EditProductConfirm from '../Components/EditProductConfirm';
import Spinner from '@/components/Spinner';


function SubscriptionLOS() {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState<string>('updatedAt');
  const [order, setOrder] = useState<string>('DESC');
  const [showDropDown, setShowDropDown] = useState(false);
  const [showToolTip, setShowToolTip] = useState<number | null>(null);


  //const isEdit = useAppSelector(state => state.settings.isLosSettingsEdit);
  const displayNameInputRef = useRef<HTMLInputElement>(null);
  const losConfigurations = useAppSelector(
    state => state.settings.losConfigurations
  );
  const isLosConfigurationsLoading = useAppSelector(
    state => state.settings.isLosConfigurationsLoading
  );

  const { usersList, isLoading, usersListBody, isResendInviteModalOpen } =
    useAppSelector((state: RootState) => state.users);

  const [isOpen, setIsOpen] = useState(false)
  const limit = LIMIT;
  const offset = currentPage * limit;
  // const totalPages = Math.ceil(usersList?.count / limit);
  // const indexOfLastItem = offset + usersListLength();

  const { id } = useParams<{ id: string }>()

  const dispatch = useDispatch();

  const [products, setProducts] = useState<ProductListEntity[] | []>(losConfigurations?.productList)
  const [hecmProducts, setHecmProducts] = useState<ProductListEntity[] | []>([]);

  const isUserLimitReachedModalState = useAppSelector(
    state => state.settings.modals.userLimitReached
  );


  if (!id) {
    return <div>Error: No organization ID provided.</div>;
  }


  const handleOptionClick = (option: ProductListEntity) => {

    let updatedProductList = hecmProducts.map(product => {
      if (product.product_name === option.product_name && product.los_id === option.los_id) {
        return { ...product, is_active: !option.is_active };
      }

      if (typeof product.los_id !== 'number') {
        return { ...product, los_id: Number(option.los_id) || 0 }
      }

      return product;
    });
    setHecmProducts(updatedProductList);
    //setSearchText('');
  };

  useEffect(() => {
    if (losConfigurations) {
      setProducts(losConfigurations?.productList)
    }
  }, [isLosConfigurationsLoading])


  useEffect(() => {

    setHecmProducts(losConfigurations?.productList);
  }, [losConfigurations]);

  useEffect(() => {
    dispatch(settingSlice.actions.getLosConfigurations(id));
    return () => {
      dispatch(settingSlice.actions.setIsLosSettingsEdit(false));
    };
  }, []);

  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  // Custom hook
  const debouncedValue = useDebounce(searchQuery, 500);

  // Return the length of users list rows array
  const usersListLength = (): number => {
    return usersList?.rows?.length;
  };

  const dropdownRef: any = useRef(null);
  const [toolTipRefs, setToolTipRefs] = useState(
    Array(usersListLength()).map(() => createRef<HTMLDivElement>())
  );

  useEffect(() => {
    setToolTipRefs(elRefs =>
      Array(usersListLength())
        .fill(null)
        .map((_, i) => elRefs[i] || createRef<HTMLDivElement>())
    );
  }, [usersList.rows]);

  useEffect(() => {
    // Add event listener to document when the dropdown is shown
    if (showDropDown) {
      document.addEventListener('click', handleClickOutside);
    }
    if (showToolTip !== null) {
      document.addEventListener('click', handleClickOutside);
    }
    // Clean up the event listener when the component unmounts or the dropdown is hidden
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropDown, showToolTip]);

  // To call users list api in cases of pages, sort, order, search and status
  useMemo(() => {
    if (usersListBody) {
      dispatch(usersSlice.actions.getUsersList(usersListBody as UserListBody));
    }
  }, [usersListBody]);


  // Check if the click is outside the dropdown
  const handleClickOutside = (event: { target: any }) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
    if (
      showToolTip !== null &&
      toolTipRefs &&
      toolTipRefs?.length &&
      !toolTipRefs?.some((toolTipRef: any) =>
        toolTipRef.current?.contains(event.target)
      )
    ) {
      setShowToolTip(null);
    }
  };


  // Open or close the resend invite modal

  // Handle status filter dropdown values

  // To handle displaying the appropriate filter status on the screen as per toggle
  const handleIsOpen = (isOpen: boolean) => {
    setIsOpen(isOpen)
  }

  // To reset the page when all the entries in a page are deleted
  useEffect(() => {
    if (!usersList.rows.length && !searchQuery.length && currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [usersList.rows, searchQuery, currentPage]);


  const handleInputChange = (index: number, value: string) => {
    setHecmProducts((prevProducts) =>
      prevProducts.map((product, i) =>
        i === index ? { ...product, display_name: value || "" } : product
      )
    );
  };

  const handleClear = () => {
    dispatch(settingSlice.actions.getLosConfigurations(id))
  }

  const handleEditProducts = () => {
    let filteredHecmProducts = hecmProducts.filter(
      product => product.is_active
    );

    filteredHecmProducts = filteredHecmProducts.map(product => {

      if (typeof product.los_id !== 'number') {
        return { ...product, los_id: Number(product.los_id) || 0 }
      }

      return product;
    });

    const payload: any = {
      los_settings: {
        id: losConfigurations?.id ? losConfigurations?.id : null,
        organization_id: id
      },
      hecm_products: filteredHecmProducts,
    };
    dispatch(settingSlice.actions.saveOrUpdateLosConfigurations(payload));
    setIsEditMode(false)
  }

  useEffect(() => {
    !isLosConfigurationsLoading && handleIsOpen(false)
  }, [isLosConfigurationsLoading])


  return (
    <div className="flex flex-col p-5">
      {isLosConfigurationsLoading ? (
        <LosSettingsPageLoader />
      ) : (
        <>

          <div className="flex flex-col mt-2">

            <div className="flex flex-col h-fit font-Inter">
              <div className="p-4 pt-0  flex justify-between items-center">
                <div className="w-[460px]">
                </div>
                {!isLoading ? (
                  <>

                    {!isEditMode && losConfigurations && losConfigurations?.productList?.length != 0 && <div
                      onClick={toggleEditMode}
                      className='cursor-pointer'><EditIcon /></div>}
                  </>
                ) : (
                  <Skeleton count={1} width={200} height={40} borderRadius={10} />
                )}
              </div>

              <div className="flex flex-col">
                {/* Table Header */}
                <div className="flex w-full items-center bg-interLightBlack px-4 h-8 border-l border-r border-white">
                  {Boolean(losConfigurations?.productList?.length || isLoading) && (
                    <div className="flex w-full">
                      {/* Column 1: Empty (for icons or actions if needed) */}
                      <div className="w-1/5">
                        <TableRowHeader
                          className=''
                          title=""
                          sort={true}
                          setOrder={setOrder}
                          setSort={setSort}
                          order={order}
                          textColor="white"
                        />
                      </div>
                      {/* Column 2: Product Name */}
                      <div className="w-2/5">
                        <TableRowHeader
                          className=''
                          title="Product Name"
                          sort={false}
                          textColor="white"
                        />
                      </div>
                      {/* Column 3: Status */}
                      <div className="w-1/5">
                        <TableRowHeader
                          className=''
                          title="Display Name"
                          sort={false}
                          textColor="white"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* Table Body */}
                <div className="flex flex-col table-layout overflow-y-auto border-l border-r border-white">
                  {losConfigurations?.productList?.length || isLoading ? (
                    hecmProducts?.map((item: ProductListEntity, index: number) => {
                      //  const error = validateInput(item.display_name || "");
                      return <div
                        key={index}
                        className="flex border-b border-sideNavButtonBg w-full items-center justify-between h-14 min-h-[56px] px-4 hover:bg-sideNavButtonBg cursor-pointer"
                      >
                        {/* Column 1: Empty (Placeholder for icons/actions) */}
                        <div className="w-1/5 flex items-start">
                          {!isLoading ? (
                            <span className="text-sm font-medium text-greyWhiteColor truncate">

                              <input ref={displayNameInputRef} disabled={!isEditMode || ['CONVENTIONAL', 'ALL_PRICE'].includes(item?.product_type)} value={item?.display_name} checked={item.is_active} type="checkbox" onChange={() => handleOptionClick(item)} />
                            </span>
                          ) : (
                            <Skeleton count={1} width={167} height={18} borderRadius={10} />
                          )}
                        </div>

                        {/* Column 2: Product Name */}
                        <div className="w-2/5 flex items-start">
                          {!isLoading ? (
                            <span className="text-sm font-bold capitalize text-greyWhiteColor truncate">
                              {item?.product_name?.toLocaleLowerCase()}
                            </span>
                          ) : (
                            <Skeleton count={1} width={167} height={18} borderRadius={10} />
                          )}
                        </div>

                        {/* Column 3: Status */}
                        <div className="w-2/5 flex items-start">
                          {!isLoading ? (
                            <input
                              disabled={!isEditMode || !item?.is_active}
                              value={item.display_name || ""}
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              className={`border p-1 border-gray-300 h-8
                                  }`}
                              type="text" />
                          ) : (
                            <Skeleton count={1} width={167} height={18} borderRadius={10} />
                          )}
                        </div>
                      </div>
                    })
                  ) : !!debouncedValue ? (
                    <NoUsersFound searchInput={debouncedValue} />
                  ) : (
                    <div className="flex flex-col h-full items-center">
                      <img
                        className="mt-20 w-[163px] h-[71px]"
                        alt=""
                      />
                      <h2 className="text-interBlack text-xl font-medium py-2">
                        No products are listed yet
                      </h2>
                    </div>
                  )}
                </div>

              </div>

              {isEditMode && <div className=' flex justify-end'>
                <Button
                  onClick={() => {
                    toggleEditMode()
                    handleClear()
                  }
                  }
                  text="Cancel"
                  type="button"
                  className="bg-white inline-flex  w-[10%] mr-3 h-10 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
                />
                <button
                  type="button"
                  // onClick={onSave}
                  onClick={() => {
                    handleIsOpen(!isOpen)
                  }}
                  className={`inline-flex w-[10%] ml-3 h-10 border border-buttonLightGreen 
         
               'hover:bg-white hover:text-buttonLightGreen'
         
      bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
                >
                  {isLosConfigurationsLoading ? <Spinner /> : 'Save'}
                </button>
              </div>}

              <Modal
                // isOpen={isResendInviteModalOpen}
                isOpen={isOpen}
                ariaHideApp={false}
                className="mx-auto  rounded shadow-modal"
                style={{
                  overlay: {
                    display: 'flex',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.61)',
                  },
                }}
              >
                <EditProductConfirm isOpen={isOpen} handleIsOpen={handleIsOpen} handleEditProducts={handleEditProducts} />
              </Modal>

              <Modal
                isOpen={isUserLimitReachedModalState}
                ariaHideApp={false}
                className="mx-auto  rounded shadow-modal"
                style={{
                  overlay: {
                    display: 'flex',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.61)',
                  },
                }}
              >
                <UserLimitModel />
              </Modal>
            </div>



          </div>
          {/* )} */}
        </>
      )}
    </div>
  );
}

export default SubscriptionLOS;
