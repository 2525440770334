import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { RootState } from '@/store/index';

import Modal from 'react-modal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import usersSlice from '../../store/slice/users.slice';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';

import RemoveUser from '../Users/RemoveUser';
import EditUser from '../AddUser/EditUser';
import PerformanceChart from '../Components/PerformanceChart';
import UserDetailsLoading from './UserDetailsLoading';

import {
  capitalizeFirstLetter,
  convertDateToHoursOrSeconds,
  formatDate,
  handleImageExtension,
  handleSelectedImage,
} from '@/lib/helper';

import { USER_DETAILS } from '@/src/routes/routesConstants';

import DeleteIcon from '../../../../assets/images/svg/delete_icon.svg';
import EditIcon from '../../../../assets/images/svg/edit_icon.svg';
import RemoveUserLogo from '../Components/RemoveUserLogo';
import { InputTypes } from '@/src/enums/enums';
import { ALLOWED_IMAGE_FILE_TYPES, ALLOWED_IMAGE_FILE_TYPES_ARRAY } from '@/lib/constants';
import { toast } from 'react-toastify';
import UploadUserLogo from '../Components/UploadUserLogo';

const UserDetails = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const allowedFileTypes: string = ALLOWED_IMAGE_FILE_TYPES;
  const maxImageSize: number = 1000000;

  const {
    isRemoveUserModalOpen,
    isEditUserModalOpen,
    isUserDetailsLoading,
    userDetails,
  } = useAppSelector((state: RootState) => state.users);

  const [searchParams] = useSearchParams();
  const imageSelect = useRef<HTMLInputElement>(null);
  const [imageExtension, setImageExtension] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [isUserDeleteModalOpen, setIsUserDeleteModalOpen] = useState<boolean>(false)

  const org_id: any = searchParams.get('org_id');
  const organization_name: any = searchParams.get('organization_name')
  const first_name = searchParams.get('first_name');
  const last_name = searchParams.get('last_name');
  const page = Number(searchParams.get('page'));
  const search = searchParams.get('search');
  const order = searchParams.get('order');
  const sort = searchParams.get('sort');
  const status = searchParams.get('status');

  const { id } = useParams<{ id: string }>() || { id: '' };
  const removeUserDetails = {
    id: id as string,
    first_name: first_name as string,
    last_name: last_name as string,
  };

  const [isLoading, setIsLoading] = useState<{
    isImageLoading: boolean;
    isDetailsLoading: boolean;
  }>({
    isImageLoading: false,
    isDetailsLoading: false,
  });

  const [isModalOpen, setIsModalOpen] = useState<{
    uploadModal: boolean;
    removeLogo: boolean;
  }>({
    uploadModal: false,
    removeLogo: false,
  });

  const [viewLicensedStatesModal, setViewLicensedStatesModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (page && search && status)
      dispatch(
        usersSlice.actions.setUsersListBody({
          page: page,
          search: search,
          order: order ? order : 'DESC',
          sort: sort ? sort : 'updatedAt',
          status: status.split(','),
        })
      );
  }, [page, search, order, sort, status]);

  const handleRemoveUser = (isOpen: boolean): void => {
    setIsUserDeleteModalOpen(isOpen)
    // dispatch(usersSlice.actions.setIsRemoveUserModalState(true));
  };

  // To call the user details api and set the first_name and last_name as breadcrumbs in the page
  const handleUserDetails = async (): Promise<void> => {
    dispatch(
      settingSlice.actions.setBreadcrumbText(`${first_name} ${last_name}`)
    );
    if (id) dispatch(usersSlice.actions.getUserDetails({ userId: id }));
  };

  const handleEditUserModal = (): void => {
    dispatch(usersSlice.actions.setIsEditUserModalOpen(true));
  };

  // Call the user details fetch function when the page is rendered
  useMemo(() => {
    handleUserDetails();
  }, [window.location.host.includes(USER_DETAILS)]);



  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0] as File;
      if (
        file &&
        typeof handleSelectedImage(
          file.size,
          maxImageSize,
          file.type,
          ALLOWED_IMAGE_FILE_TYPES_ARRAY
        ) === InputTypes.NUMBER
      ) {
        // To set preview image
        setImageExtension(handleImageExtension(file.type));
        setSelectedFile(file);
        const objectUrl = window.URL.createObjectURL(file);
        setPreviewImage(objectUrl as string);
      } else {
        toast.error(
          handleSelectedImage(
            file.size,
            maxImageSize,
            file.type,
            ALLOWED_IMAGE_FILE_TYPES_ARRAY
          ),
          {
            theme: 'colored',
          }
        );
      }
    }
  };

  useMemo(() => {
    if (previewImage) setIsModalOpen(prev => ({ ...prev, uploadModal: true }));
  }, [previewImage]);

  return (
    <div className="flex flex-col bg-mainBackground h-full font-Inter customer-details  overflow-y-auto">
      <div className=" w-full shadow-pageShadow bg-white h-[194px] flex rounded-lg">
        <div className="w-1/2 md:w-2/4 bg-greyBlackColor rounded-tl-lg rounded-bl-lg  flex flex-col justify-between px-6 pt-10 pb-6">
          <div className="flex items-center w-full">
            {!isUserDetailsLoading ? (
              <>
                <div className={`flex justify-center items-center ${!userDetails?.user_profile?.logo && 'bg-interBlack  border-greenLightColor border'} w-12 h-12 rounded `}>
                  {userDetails?.user_profile?.logo ? <img
                    src={userDetails?.user_profile?.logo}
                    className="rounded-full h-full"
                  /> :
                    <span className="uppercase text-xl font-semibold text-white">{`${userDetails?.user_profile?.first_name
                      ? userDetails?.user_profile?.first_name?.charAt(0)
                      : '-'
                      }${userDetails?.user_profile?.last_name
                        ? userDetails?.user_profile?.last_name?.charAt(0)
                        : '-'
                      }`}</span>}
                </div>
                <div>
                  <div>
                    <span className="text-lg font-semibold pl-2 text-white capitalize w-[85%]">{`${userDetails?.user_profile?.first_name
                      ? capitalizeFirstLetter(
                        userDetails?.user_profile?.first_name
                      )
                      : '--'
                      } ${userDetails?.user_profile?.last_name
                        ? capitalizeFirstLetter(
                          userDetails?.user_profile?.last_name
                        )
                        : '--'
                      }`}</span>
                  </div>
                  <div>
                    <span className="text-sm font-light pl-2 text-white capitalize w-[85%]">{`${userDetails?.role?.name ? userDetails?.role?.name : '--'
                      }`}</span>
                  </div>

                </div>

              </>
            ) : (
              <SkeletonTheme baseColor="#f2f4f71a" enableAnimation={false}>
                <Skeleton count={1} height={42} width={42} borderRadius={6} />
                <div className="mx-1"></div>
                <div className="flex flex-col">
                  <Skeleton
                    count={1}
                    height={22}
                    width={160}
                    borderRadius={10}
                  />
                  <Skeleton
                    count={1}
                    height={22}
                    width={160}
                    borderRadius={10}
                  />
                </div>
              </SkeletonTheme>
            )}
          </div>

          <div className='flex mb-5 mt-2'>
            {userDetails?.user_profile && <button
              className="flex mr-3 justify-center items-center text-sm w-32 h-7 font-medium text-greyLightColor bg-greyLightColorOpacity hover:bg-[#f2f4f780] rounded"
              onClick={() => { dispatch(usersSlice.actions.setIsRemoveUserModalState(true)) }}
            >
              <label
                htmlFor="files"
                className="w-32 cursor-pointer border flex items-center border-greenLightColor text-green-900 rounded h-7 text-sm font-medium px-4 hover:bg-greenLightColor hover:text-white"
              >
                {userDetails?.user_profile?.logo ? 'Change' : 'Upload'} Logo
              </label>
              <input
                type={InputTypes.FILE}
                id="files"
                className="hidden"
                ref={imageSelect}
                accept={allowedFileTypes}
                onChange={handleImageChange}
              />

            </button>}

            {userDetails?.user_profile?.logo && <button
              className="flex border-[1px] text-red-200 border-red-600  mr-3 justify-center items-center text-xs w-32 h-7 font-bold bg-greyLightColorOpacity hover:bg-[#f2f4f780] rounded"
              onClick={() =>
                setIsModalOpen(prev => ({ ...prev, removeLogo: true }))
              }
            >

              <span className="pl-2 ">Remove Logo </span>
            </button>}
          </div>

          <div className="flex w-full">
            {!isUserDetailsLoading ? (
              <>

                <button
                  className="flex mr-3 justify-center items-center text-xs w-44 h-9 font-medium text-greyLightColor bg-greyLightColorOpacity hover:bg-[#f2f4f780] rounded"
                  onClick={() => { handleRemoveUser(true) }}
                >
                  <img src={DeleteIcon} alt="" />
                  <span className="pl-2">Remove User </span>
                </button>
                <button
                  className="flex justify-center items-center text-xs w-44 h-9 font-medium text-greyLightColor bg-greyLightColorOpacity hover:bg-[#f2f4f780] rounded"
                  onClick={handleEditUserModal}
                >
                  <img src={EditIcon} alt="" />
                  <span className="pl-2">Edit User </span>
                </button>
              </>
            ) : (
              <>
                <div className="mr-3">
                  <SkeletonTheme baseColor="#f2f4f71a" enableAnimation={false}>
                    <Skeleton count={1} height={30} width={173} />
                  </SkeletonTheme>
                </div>
                <div>
                  <SkeletonTheme baseColor="#f2f4f71a" enableAnimation={false}>
                    <Skeleton count={1} height={30} width={173} />
                  </SkeletonTheme>
                </div>
              </>
            )}
          </div>
        </div>
        {!isUserDetailsLoading ? (
          <>
            <div className="flex flex-col py-7 pl-5 pr-2 w-1/2">
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor">
                <div className="w-1/2 md:w-1/4">
                  <span>Email</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.email
                      ? userDetails?.email?.toLocaleLowerCase()
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>Last Active</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {' '}
                    {userDetails?.user_activity?.last_activity_time
                      ? convertDateToHoursOrSeconds(
                        userDetails?.user_activity?.last_activity_time
                          ? new Date(
                            userDetails?.user_activity?.last_activity_time
                          )
                          : new Date()
                      )
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>NMLS</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.user_profile?.nmls
                      ? userDetails?.user_profile?.nmls
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>Invited</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.createdAt
                      ? formatDate(String(userDetails?.createdAt), false)
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>Joined</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.joined_at
                      ? formatDate(String(userDetails?.joined_at), false)
                      : '--'}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col py-7 pl-5 w-3/4">
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor">
                <div className="w-1/2 md:w-1/4">
                  <span>Address</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.user_profile?.address_line_1
                      ? userDetails?.user_profile?.address_line_1
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>City</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {' '}
                    {userDetails?.user_profile?.city
                      ? userDetails?.user_profile?.city
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>State</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.user_profile?.user_state?.name
                      ? userDetails?.user_profile?.user_state?.name
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/2 md:w-1/4">
                  <span>Zip Code</span>
                </div>
                <div className="w-1/2 md:w-9/12">
                  <span className="text-greyBlackColor">
                    {userDetails?.user_profile?.zip_code
                      ? userDetails?.user_profile?.zip_code
                      : '--'}
                  </span>
                </div>
              </div>
              <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
                <div className="w-1/4 md:w-1/4">
                  <span>State licensed</span>
                </div>
                <div className="w-full md:w-9/12 relative">
                  <span className="text-greyBlackColor">
                    {userDetails?.licensed_states?.length ? (
                      <>
                        {userDetails?.licensed_states?.length > 5 ? (
                          <div>
                            <div>
                              {userDetails?.licensed_states
                                ?.slice(0, 4)
                                .map((item, index) => (
                                  <span
                                    key={index}
                                    className="w-full mt-1"
                                  >{`${item?.state?.code},`}</span>
                                ))}
                              <span
                                onClick={() =>
                                  setViewLicensedStatesModal(prev => !prev)
                                }
                                className="cursor-pointer"
                              >
                                {`${viewLicensedStatesModal
                                  ? '...less'
                                  : '...more'
                                  }`}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="">
                            {userDetails?.licensed_states?.map(
                              (item, index) => (
                                <span
                                  key={index}
                                  className="w-full mt-1"
                                >{`${item?.state?.code},`}</span>
                              )
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      '--'
                    )}
                  </span>

                  {viewLicensedStatesModal && (
                    <div className="flex flex-wrap absolute bg-white rounded-lg border shadow-card p-3 top-7 right-3">
                      {userDetails?.licensed_states.map((item, index) => (
                        <div key={index} className="w-1/6 text-center">
                          {`${item?.state?.code},`}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <UserDetailsLoading />
        )}
      </div>

      <PerformanceChart userDetails={userDetails} />

      <Modal
        isOpen={isUserDeleteModalOpen}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <RemoveUser handleRemoveUserModal={handleRemoveUser} removeUserDetails={removeUserDetails} org_id={org_id} organization_name={organization_name} />
      </Modal>

      <Modal
        isOpen={isModalOpen.removeLogo}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <RemoveUserLogo
          id={id || ''}
          setIsModalOpen={setIsModalOpen}
          handleUserDetails={handleUserDetails}
        />
      </Modal>

      <Modal
        isOpen={isEditUserModalOpen}
        ariaHideApp={false}
        className="mx-auto rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <EditUser />
      </Modal>

      {
        isModalOpen.uploadModal && (
          <Modal
            isOpen={isModalOpen.uploadModal}
            ariaHideApp={false}
            className="mx-auto rounded-lg shadow-modal"
            style={{
              overlay: {
                display: 'flex',
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.61)',
              },
            }}
            onAfterClose={() => {
              if (imageSelect.current) {
                imageSelect.current.value = '';
              }
            }}
          >
            <UploadUserLogo
              id={id || ''}
              previewImage={selectedFile ? previewImage : ''}
              imageExtension={imageExtension}
              isLoading={isLoading}
              setIsModalOpen={setIsModalOpen}
              setIsLoading={setIsLoading}
              handleSettingsDetail={handleUserDetails}
            />
          </Modal>
        )
      }
    </div >
  );
};

export default UserDetails;
