import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import usersSlice from '../../store/slice/users.slice';
import {
  UserDetailsTypes,
  UserListBody,
} from '../../interface/users.interface';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { RootState } from '@/store/index';
import useDebounce from '@/hooks/useDebounce';

import { Tooltip } from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import Modal from 'react-modal';
import ResendInvite from './ResendInvite';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import SearchInput from '@/components/Search';
import TableRowHeader from '@/components/Table';
import Pagination from '@/components/Pagination';
import NoUsersFound from './NewUsersFound';
import UserLimitModel from './UserLimit';

import {
  capitalizeFirstLetter,
  convertDateToHoursOrSeconds,
  formatDate,
  randomBGColor,
  randomTextColor,
} from '@/lib/helper';
import { LIMIT } from '@/lib/constants';

import NoDataIcon from '../../../../assets/images/svg/noCustomer_icon.svg';
import MenuVerticalIcon from '../../../../assets/images/svg/menu-vertical_icon.svg';
import DropDownIcon from '../../../../assets/images/svg/down-grey_icon.svg';
import { USER_DETAILS } from '@/src/routes/routesConstants';
import AddUser from '../AddUser/AddUser';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';

const UserList = (): JSX.Element => {
  const dispatch = useAppDispatch();

  // Redux states
  const { usersList, isLoading, usersListBody, isResendInviteModalOpen } =
    useAppSelector((state: RootState) => state.users);



  // Local states
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState<string>('updatedAt');
  const [order, setOrder] = useState<string>('DESC');
  const [status, setStatus] = useState<string[]>(['active']);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showToolTip, setShowToolTip] = useState<number | null>(null);
  const [selectedOrganizationalUser, setSelectedOrganizationalUser] =
    useState<UserDetailsTypes | null>(null);

  const { isRemoveUserLoading } = useAppSelector(
    (state: RootState) => state.users
  );

  const isUserLimitReachedModalState = useAppSelector(
    state => state.settings.modals.userLimitReached
  );
  const isAddUserOpen = useAppSelector((state: RootState) => state.settings.modals.addUser);
  const navigate = useNavigate()
  // Custom hook
  const debouncedValue = useDebounce(searchQuery, 500);

  // Return the length of users list rows array
  const usersListLength = (): number => {
    return usersList?.rows?.length;
  };

  const limit = LIMIT;
  const offset = currentPage * limit;
  const totalPages = Math.ceil(usersList?.count / limit);
  const indexOfLastItem = offset + usersListLength();

  const [params] = useSearchParams();
  const organization_id = params.get('org_id');
  const organization_name: string = params.get('organization_name') || ''

  const dropdownRef: any = useRef(null);
  const pageRef = useRef<HTMLInputElement>(null);
  const [toolTipRefs, setToolTipRefs] = useState(
    Array(usersListLength()).map(() => createRef<HTMLDivElement>())
  );

  useEffect(() => {
    setToolTipRefs(elRefs =>
      Array(usersListLength())
        .fill(null)
        .map((_, i) => elRefs[i] || createRef<HTMLDivElement>())
    );
  }, [usersList.rows]);

  const toggleToolTip = (
    e: React.MouseEvent<HTMLImageElement>,
    index: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setShowToolTip(index);
  };

  useEffect(() => {
    // Add event listener to document when the dropdown is shown
    if (showDropDown) {
      document.addEventListener('click', handleClickOutside);
    }
    if (showToolTip !== null) {
      document.addEventListener('click', handleClickOutside);
    }
    // Clean up the event listener when the component unmounts or the dropdown is hidden
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropDown, showToolTip]);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  // Handle body data for all functionalities and fire the organizationList api accordingly
  useMemo(() => {
    if (organization_id)
      dispatch(
        usersSlice.actions.setUsersListBody({
          page: currentPage,
          search: debouncedValue,
          order: order ? order : 'DESC',
          sort: sort ? sort : 'updatedAt',
          status: status,
          organization_id,
        })
      );
  }, [currentPage, debouncedValue, order, sort, status, organization_id]);

  // To call users list api in cases of pages, sort, order, search and status
  useMemo(() => {
    if (usersListBody) {
      dispatch(usersSlice.actions.getUsersList(usersListBody as UserListBody));
    }
  }, [usersListBody]);

  useEffect(() => {
    dispatch(usersSlice.actions.getUsersLimit(organization_id));
  }, []);

  // Check if the click is outside the dropdown
  const handleClickOutside = (event: { target: any }) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
    if (
      showToolTip !== null &&
      toolTipRefs &&
      toolTipRefs?.length &&
      !toolTipRefs?.some((toolTipRef: any) =>
        toolTipRef.current?.contains(event.target)
      )
    ) {
      setShowToolTip(null);
    }
  };

  // Open or close the resend invite modal
  const handleResendInviteModal = (isOpen: boolean) => {
    dispatch(usersSlice.actions.setIsResendInviteModalState(isOpen));
  };

  // Handle status filter dropdown values
  const handleOptionSelect = (value: string) => {
    setCurrentPage(0);
    switch (value) {
      case 'active':
        setStatus(['active']);
        break;
      case 'invited':
        setStatus(['invited']);
        break;
      case 'cancelled':
        setStatus(['cancelled']);
        break;
      case 'inactive':
        setStatus(['inactive']);
        break;
      case 'pending':
        setStatus(['pending']);
        break;
      case 'expired':
        setStatus(['expired']);
        break;
      case 'all':
        setStatus([]);
        break;
      default:
        break;
    }
    setShowDropDown(false);
  };

  // To handle displaying the appropriate filter status on the screen as per toggle
  const handleDisplayStatusOnClick = (stat: string[]): string => {
    if (stat.includes('invited') && stat.length === 1) {
      return 'invited';
    } else if (stat.includes('active') && stat.length === 1) {
      return 'active';
    } else if (stat.includes('cancelled') && stat.length === 1) {
      return 'cancelled';
    } else if (stat.includes('inactive') && stat.length === 1) {
      return 'inactive';
    } else if (stat.includes('pending') && stat.length === 1) {
      return 'pending'
    } else if (stat.includes('expired') && stat.length === 1) {
      return 'expired';
    } else {
      return 'all';
    }
  };

  const handleSearch = (value: string): void => {
    setCurrentPage(0);
    setSearchQuery(value);
  };

  const tableRows: typeof isLoading extends true ? any[] : UserDetailsTypes[] =
    useMemo(
      () => (isLoading ? Array(10).fill(1) : usersList?.rows ?? []),
      [isLoading, usersList.rows]
    );

  // To reset the page when all the entries in a page are deleted
  useEffect(() => {
    if (!usersList.rows.length && !searchQuery.length && currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [usersList.rows, searchQuery, currentPage]);

  // Clearing the values of usersListBody
  useEffect(() => {
    return () => {
      dispatch(usersSlice.actions.setUsersListBody(null));
    };
  }, []);

  const handleUserDetails = (
    e: React.MouseEvent<HTMLDivElement>,
    {
      id,
      first_name,
      last_name,
    }: { id: string; first_name: string; last_name: string }
  ): void => {
    e.preventDefault();
    if (id && first_name && last_name)
      navigate({
        pathname: `${USER_DETAILS}/{${id}}/`,
        search: createSearchParams({
          id,
          org_id: organization_id!,
          first_name,
          last_name,
          page: currentPage.toString(),
          search: debouncedValue,
          order: order ? order : 'DESC',
          sort: sort ? sort : 'updatedAt',
          status: status,
          organization_name: organization_name
        }).toString(),
      });
  };

  return (
    <div className="flex flex-col h-screen font-Inter">
      <div className="p-4 flex justify-between items-center">
        <div className="w-[460px]">
          <SearchInput
            name="search"
            placeholder="Search users by name or email address"
            value={searchQuery}
            onSearch={handleSearch}
            autoFocus={true}
          />
        </div>

        {
          !isLoading && <button
            onClick={() => { dispatch(settingSlice.actions.setAddUserModalState(true)) }}
            className='ms-[600px] text-xs border border-buttonColorGreen text-buttonColorGreen items-center h-8 px-6 rounded bg-white'>Add User</button>
        }
        {!isLoading ? (
          <>
            <div
              className={`relative flex bg-mainBackground text-xs font-medium border border-mainBackground text-greyBlackLightColor rounded h-[33px] w-[139px] w-min-[200px] items-center px-4 justify-between
        ${showDropDown ? 'border-greyBlackLightColor' : ''}
        `}
              ref={dropdownRef}
            >

              <span className="">Status: </span>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={toggleDropDown}
              >
                <span className="pl-2 capitalize">
                  {handleDisplayStatusOnClick(status)}
                </span>
                <img className="pl-3" src={DropDownIcon} alt="" />
              </div>
              {showDropDown && (
                <div className="absolute shadow-card rounded-md top-9 left-0 bg-white w-full z-10">
                  <div className="flex flex-col text-xs font-medium text-greyBlackColor text-right ">
                    <span
                      className={` px-4 py-2 cursor-pointer ${!status.length
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('all')}
                    >
                      All
                    </span>
                    <span
                      className={` px-4 py-2 cursor-pointer ${status[0] === 'active'
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('active')}
                    >
                      Active
                    </span>
                    <span
                      className={` px-4 py-2 cursor-pointer ${status[0] === 'invited'
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('invited')}
                    >
                      Invited
                    </span>
                    <span
                      className={` px-4 py-2 cursor-pointer ${status[0] === 'cancelled'
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('cancelled')}
                    >
                      Cancelled
                    </span>
                    <span
                      className={` px-4 py-2 cursor-pointer ${status[0] === 'inactive'
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('inactive')}
                    >
                      Inactive
                    </span>

                    <span
                      className={` px-4 py-2 cursor-pointer ${status[0] === 'pending'
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('pending')}
                    >
                      Pending
                    </span>

                    <span
                      className={` px-4 py-2 cursor-pointer ${status[0] === 'expired'
                        ? 'text-blackLightColor font-semibold'
                        : ''
                        }`}
                      onClick={() => handleOptionSelect('expired')}
                    >
                      Expired
                    </span>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <Skeleton count={1} width={200} height={40} borderRadius={10} />
        )}
      </div>

      <div className="flex flex-col ">
        <div className="flex w-full items-center bg-interLightBlack px-4 h-8 border-l border-r border-white">
          {Boolean(usersListLength() || isLoading) && (
            <div className="flex w-full">
              <div className="w-2/5">
                <TableRowHeader
                  title={'User'}
                  sort={true}
                  setOrder={setOrder}
                  setSort={setSort}
                  order={order}
                  textColor="white"
                  className={''}
                />
              </div>
              <div className="w-1/5">
                <TableRowHeader
                  title={'Email'}
                  sort={false}
                  textColor="white"
                  className={''}
                />
              </div>
              <div className="w-1/5">
                <TableRowHeader
                  title={'Status'}
                  sort={false}
                  textColor="white"
                  className={''}
                />
              </div>
              <div className="w-1/5 flex justify-center">
                <TableRowHeader
                  title={'Last Active'}
                  sort={true}
                  setOrder={setOrder}
                  setSort={setSort}
                  order={order}
                  textColor="white"
                  className={''}
                />
              </div>
              <div className="w-5 flex justify-center">
                <TableRowHeader
                  title={''}
                  sort={false}
                  textColor="white"
                  className={''}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col table-layout overflow-y-auto border-l border-r border-white">
          {usersListLength() || isLoading ? (
            <div>
              {tableRows.map((item, index) => (
                <div
                  key={index}
                  onClick={e => {
                    if (!isRemoveUserLoading) {
                      e.stopPropagation();
                      handleUserDetails(e, {
                        id: item?.id,
                        first_name: item?.user_profile?.first_name,
                        last_name: item?.user_profile?.last_name,
                      });
                    }
                  }}
                  className={`flex border-b border-sideNavButtonBg w-full items-center justify-between h-14 min-h-[56px] px-4
                  hover:bg-sideNavButtonBg cursor-pointer`}
                >
                  <div className="flex items-center w-2/5">
                    <div className="flex items-center w-full">
                      <div
                        className="h-33 w-33 rounded-md flex items-center justify-center font-semibold"
                        style={{
                          backgroundColor: randomBGColor(
                            item?.user_profile?.first_name[0]
                          ),
                        }}
                      >
                        {!isLoading ? (
                          !item?.user_profile?.logo ? <span
                            className="uppercase text-xs"
                            style={{ color: randomTextColor() }}
                          >
                            {item?.user_profile?.first_name
                              .split(' ')
                              .map(namePart => namePart.charAt(0))
                              .join('')}
                          </span>
                            :
                            <img src={item?.user_profile?.logo} alt="" />
                        ) : (
                          <Skeleton
                            count={1}
                            width={34}
                            height={33}
                            borderRadius={6}
                          />
                        )}
                      </div>
                      {!isLoading ? (
                        <span className="text-sm font-semibold text-interBlack capitalize pl-2 truncate w-[90%]">{`${capitalizeFirstLetter(
                          item?.user_profile?.first_name
                        )} ${capitalizeFirstLetter(
                          item?.user_profile?.last_name
                        )}`}</span>
                      ) : (
                        <Skeleton
                          count={1}
                          width={110}
                          height={18}
                          borderRadius={10}
                          className="ml-3"
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-1/5 flex items-center ">
                    {!isLoading ? (
                      <span className="text-sm font-medium text-greyWhiteColor lowercase truncate w-11/12">
                        {item?.email?.toLocaleLowerCase()}
                      </span>
                    ) : (
                      <Skeleton
                        count={1}
                        width={167}
                        height={18}
                        borderRadius={10}
                      />
                    )}
                  </div>
                  <div className="w-1/5 flex items-center ">
                    {!isLoading ? (
                      <div
                        className={` font-medium rounded px-4 ${item?.status === 'invited' ||
                          item?.status === 'inactive'
                          ? ' bg-[#34405433] text-interBlack'
                          : item?.status === 'active' && item?.organization_subscription?.is_cancelled
                            ? 'bg-red-400' : 'bg-[#CEF3E2] text-[#007B22]'
                          } `}
                      >
                        <span className="text-sm capitalize">
                          {item?.status === 'active' && item?.organization_subscription?.is_cancelled ? 'cancelled' : item?.status}
                        </span>
                      </div>
                    ) : (
                      <Skeleton
                        count={1}
                        width={100}
                        height={18}
                        borderRadius={10}
                      />
                    )}
                  </div>

                  <div className="w-1/5 flex items-center justify-center">
                    {!isLoading ? (
                      <span
                        className="text-sm font-medium text-greyBlackColor cursor-pointer"
                        data-tooltip-id={`date-tooltip-${index}`}
                        data-tooltip-content={
                          item?.user_activity?.last_activity_time
                            ? formatDate(
                              String(item?.user_activity?.last_activity_time),
                              true
                            )
                            : '--'
                        }
                      >
                        {convertDateToHoursOrSeconds(
                          item?.user_activity?.last_activity_time
                            ? new Date(item?.user_activity?.last_activity_time)
                            : new Date()
                        )}
                      </span>
                    ) : (
                      <Skeleton
                        count={1}
                        width={97}
                        height={18}
                        borderRadius={10}
                      />
                    )}
                    <Tooltip
                      id={`date-tooltip-${index}`}
                      hidden={!item?.user_activity?.last_activity_time}
                    />
                  </div>

                  <div
                    className="w-5 flex justify-center relative"
                    ref={toolTipRefs[index]}
                  >
                    {!isLoading && (
                      <img
                        src={MenuVerticalIcon}
                        alt=""
                        className="cursor-pointer"
                        onClick={e => {
                          setSelectedOrganizationalUser(item);
                          toggleToolTip(e, index);
                        }}
                      />
                    )}
                    {showToolTip === index && (
                      <div
                        className={`absolute shadow-card rounded z-10 bg-white w-28 ${usersList.rows.length > 2 &&
                          index >= usersList.rows.length - 3
                          ? '-left-28 bottom-4'
                          : '-left-24 top-8'
                          }`}
                      >
                        <div className="flex flex-col text-sm font-normal">
                          {item?.status.toLowerCase().trim() === 'invited' && (
                            <span
                              className="h-9 cursor-pointer flex items-center justify-center text-blackLightColor shadow-lg border rounded"
                              onClick={e => {
                                e.stopPropagation();
                                handleResendInviteModal(true);
                                dispatch(
                                  usersSlice.actions.setInviteUser({
                                    resendInvite: true,
                                    inviteUser: false,
                                  })
                                );
                              }}
                            >
                              Resend Invite
                            </span>
                          )}
                          {item?.status.toLowerCase().trim() === 'pending' && (
                            <span
                              className="h-7 cursor-pointer flex items-center justify-center text-blackLightColor shadow-lg border rounded"
                              onClick={e => {
                                e.stopPropagation();
                                handleResendInviteModal(true);
                                dispatch(
                                  usersSlice.actions.setInviteUser({
                                    resendInvite: false,
                                    inviteUser: true,
                                  })
                                );
                              }}
                            >
                              Invite
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : !!debouncedValue ? (
            <NoUsersFound searchInput={debouncedValue} />
          ) : (
            <div className="flex flex-col h-full  items-center">
              <img
                className="mt-20 w-[163px] h-[71px]"
                src={NoDataIcon}
                alt=""
              />
              <h2 className="text-interBlack text-xl font-medium py-2">
                No users are listed yet
              </h2>
            </div>
          )}
        </div>

        {usersList?.count > limit && (
          <Pagination
            arrayList={usersList}
            currentPage={currentPage}
            indexOfLastItem={indexOfLastItem}
            limit={limit}
            offset={offset}
            pageRef={pageRef}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            showButtons={true}
            isLoading={isLoading}
          />
        )}
      </div>

      <Modal
        isOpen={isResendInviteModalOpen}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <ResendInvite selectedOrganizationalUser={selectedOrganizationalUser} />
      </Modal>

      <Modal
        isOpen={isUserLimitReachedModalState}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <UserLimitModel />
      </Modal>


      <Modal
        isOpen={isAddUserOpen}
        ariaHideApp={false}
        className="mx-auto rounded-lg shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >

        <AddUser organization_id={organization_id} />
      </Modal>
    </div>
  );
};

export default UserList;
