import { Dispatch, Fragment, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { useSearchParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { RootState } from '@/store/index';

import { Form, Formik } from 'formik';

import usersSlice from '../../store/slice/users.slice';

import Input from '@/components/Input';
import Button from '@/components/Button/Button';
import Select from '@/components/Select';
import SelectedStatesDropDown from '../Components/SelectedStatesDropDown';

import { formatZipCode } from '../../../../lib/helper';

import { addUserSchema } from '@/lib/validation';

import { InputTypes } from '@/src/enums/enums';

import { OrganizationUserBody } from '../../interface/users.interface';
import { StatesListBodyTypes } from '../../../Customer/interface/customer.interface';

import { LOAN_OFFICER, SPECIAL_KEYS } from '@/lib/constants';

import CloseIcon from '@/src/assets/images/svg/close_icon.svg';

const EditUser = (): JSX.Element => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const [searchParams] = useSearchParams();

  const formikRef: any = useRef();
  const id = searchParams.get('id');

  const {
    userRoles,
    statesList,
    isUserRolesLoading,
    isStatesListLoading,
    userDetails,
    isEditUserLoading,
  } = useAppSelector((state: RootState) => state.users);
  // Initial values of the form. Note that as of now the value of userRoleId is set as default as the uuid corresponding to that of Loan Officer
  const initialValues = {
    first_name: userDetails?.user_profile?.first_name
      ? userDetails?.user_profile?.first_name
      : '',
    last_name: userDetails?.user_profile?.last_name
      ? userDetails?.user_profile?.last_name
      : '',
    city: userDetails?.user_profile?.city
      ? userDetails?.user_profile?.city
      : '',
    email: userDetails?.email ? userDetails?.email : '',
    nmls: userDetails?.user_profile?.nmls
      ? userDetails?.user_profile?.nmls
      : '',
    address_line_1: userDetails?.user_profile?.address_line_1
      ? userDetails?.user_profile?.address_line_1
      : '',
    zip_code: userDetails?.user_profile?.zip_code
      ? formatZipCode(userDetails?.user_profile?.zip_code)
      : '',
    user_role_id: userDetails?.role?.id ? userDetails?.role?.id : '',
    state: userDetails?.user_profile?.user_state
      ? userDetails?.user_profile?.user_state
      : {
        code: '',
        name: '',
        id: ''
      },
    licensed_states: userDetails?.licensed_states?.length
      ? userDetails?.licensed_states?.map(item => item?.state_id)
      : [],
  };

  // Handle service api to get organization user roles
  const handleRoles = (): void => {
    dispatch(usersSlice.actions.getUserRoles());
  };

  const handleStatesList = (): void => {
    const params: StatesListBodyTypes = {
      sort: 'DESC',
      page: 0,
      limit: 60,
    };
    dispatch(usersSlice.actions.getStatesList(params));
  };

  useMemo(() => {
    handleRoles();
    handleStatesList();
  }, []);

  // Handle closing of the modal
  const handleCloseModal = (): void => {
    dispatch(usersSlice.actions.setIsEditUserModalOpen(false));
    dispatch(usersSlice.actions.setStatesList([]));
    dispatch(usersSlice.actions.setUserRoles([]));
  };

  useEffect(() => {
    if (userRoles?.length && !formikRef.current?.values.user_role_id) {
      formikRef.current?.setFieldValue(
        'user_role_id',
        userRoles?.filter(
          item =>
            item?.name?.toLocaleLowerCase()?.trim() ===
            LOAN_OFFICER.toLocaleLowerCase().trim()
        )[0]?.id
      );
    }
  }, [userRoles]);

  // Handle the create user api and to submit the values from the form
  const handleSubmit = async (values: typeof initialValues): Promise<void> => {

    if (id) {
      const editUserBody: OrganizationUserBody = {
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        email: values.email.toLocaleLowerCase().trim(),
        address_line_1: values.address_line_1.trim(),
        city: values.city.trim(),
        nmls: values.nmls,
        zip_code: values.zip_code,
        user_role_id: values.user_role_id,
        state_id: values.state?.id,
        licensed_states: values.licensed_states,
      };
      dispatch(
        usersSlice.actions.editUser({
          userId: id,
          addUserBody: { ...editUserBody, organization_user_id: id },
        })
      );
    }
  };

  return (
    <Fragment>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={addUserSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
        }): JSX.Element => (
          <Form >
            <div className="relative overflow-auto rounded-lg bg-white text-left shadow-xl transition-all w-[443px]">
              <div className="relative rounded-t-lg bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                <div className="flex justify-end" onClick={handleCloseModal}>
                  <img src={CloseIcon} alt="" className="cursor-pointer" />
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full">
                    <h3
                      className="text-xl font-semibold leading-6 text-blackDarkColor"
                      id="modal-title"
                    >
                      Edit User
                    </h3>

                    <div className="text-greyBlackLightColor text-xs mt-1 leading-2 pt-3">
                      Invite new user under your organization
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col user-model-height overflow-auto px-4 pb-4 pt-0  sm:px-6 sm:pb-4 ">
                <div className="w-full flex">
                  <div className="w-1/2 mr-1">
                    <Input
                      placeholder="First name"
                      label="Name"
                      id="first_name"
                      name="first_name"
                      type={InputTypes.TEXT}
                      value={values.first_name}
                      labelHide={true}
                      inputIcon={false}
                      errors={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderColor: `${errors.first_name && touched.first_name
                          ? '#FF3E1D'
                          : ''
                          }`,
                      }}
                      autoFocus
                    />
                  </div>

                  <div className="w-1/2 ml-1">
                    <Input
                      placeholder="Last name"
                      label=""
                      id="last_name"
                      name="last_name"
                      type={InputTypes.TEXT}
                      value={values.last_name}
                      labelHide={true}
                      inputIcon={false}
                      errors={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderColor: `${errors.last_name && touched.last_name ? '#FF3E1D' : ''
                          }`,
                      }}
                    />
                  </div>
                </div>

                <div className="w-full flex mt-4 opacity-40">
                  <Input
                    placeholder="Email"
                    label="Email"
                    id={InputTypes.EMAIL}
                    name={InputTypes.EMAIL}
                    type={InputTypes.TEXT}
                    value={values.email}
                    labelHide={true}
                    inputIcon={false}
                    errors={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                    disabled
                    onBlur={handleBlur}
                    style={{
                      borderColor: `${errors.email && touched.email ? '#FF3E1D' : ''
                        }`,
                      paddingRight: '0.5rem',
                    }}
                  />
                </div>

                <div className="w-full flex mt-5">
                  <div className="w-1/2 mr-1">
                    <Select
                      name={'Role'}
                      label="Role"
                      labelHide={true}
                      options={userRoles}
                      selected={values.user_role_id}
                      //  isLoading={isUserRolesLoading}
                      setSelected={(id: string) =>
                        setFieldValue('user_role_id', id)
                      }
                    />
                  </div>

                  <div className="w-1/2 ml-1 relative -top-2">
                    <Input
                      placeholder="NMLS Number"
                      label=" NMLS Number"
                      id="nmls"
                      name="nmls"
                      type={InputTypes.TEXT}
                      value={values.nmls}
                      inputIcon={false}
                      errors={Boolean(touched.nmls && errors.nmls)}
                      helperText={touched.nmls && errors.nmls}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={e => {
                        if (
                          !SPECIAL_KEYS.includes(e.key) &&
                          !e.metaKey &&
                          /\D/.test(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      style={{
                        borderColor: `${errors.nmls && touched.nmls ? '#FF3E1D' : ''
                          }`,
                        paddingRight: '0.5rem',
                      }}
                      disabled
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <span className="flex pt-2 pb-4 text-blackLightColor text-base font-semibold">
                    Address
                  </span>

                  <div className="w-full flex mt-1">
                    <div className="w-1/2 mr-2 relative -top-2">
                      <Input
                        placeholder="Enter the address"
                        label="Address line 1"
                        id="address_line_1"
                        name="address_line_1"
                        type={InputTypes.TEXT}
                        value={values.address_line_1}
                        colorLabel={'text-greyLightDarkColor'}
                        inputIcon={false}
                        errors={Boolean(
                          touched.address_line_1 && errors.address_line_1
                        )}
                        helperText={
                          touched.address_line_1 && errors.address_line_1
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          borderColor: `${errors.address_line_1 && touched.address_line_1
                            ? '#FF3E1D'
                            : ''
                            }`,
                          paddingRight: '0.5rem',
                        }}
                      />
                    </div>

                    <div className="w-1/2 relative -top-2">
                      <Input
                        placeholder="City"
                        label="City"
                        id="city"
                        name="city"
                        type={InputTypes.TEXT}
                        value={values.city}
                        colorLabel={'text-greyLightDarkColor'}
                        inputIcon={false}
                        errors={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          borderColor: `${errors.city && touched.city ? '#FF3E1D' : ''
                            }`,
                          paddingRight: '0.5rem',
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full flex mt-2">
                    <div className="w-1/2 mr-1">
                      <Select
                        name={'State'}
                        label="State"
                        labelHide={true}
                        options={statesList}
                        selected={values.state?.name}
                        setSelected={(name: string) => {
                          console.log(name)
                          const state = statesList.find((state) => state.name === name)
                          console.log(state)
                          setFieldValue('state', state)
                        }
                          // setSelected={(id: string) => {

                          //   setFieldValue('state_id', id)
                          // }
                        }
                        // isLoading={isStatesListLoading}
                        errors={Boolean(touched.state?.id && errors.state?.id)}
                        helperText={touched.state?.id && errors.state?.id}
                      />
                    </div>

                    <div className="w-1/2 ml-1 relative -top-2">
                      <Input
                        placeholder="------"
                        label="Zip Code"
                        id="zip_code"
                        name="zip_code"
                        type={InputTypes.TEXT}
                        value={formatZipCode(values.zip_code)}
                        inputIcon={false}
                        errors={Boolean(touched.zip_code && errors.zip_code)}
                        helperText={touched.zip_code && errors.zip_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={e => {
                          if (
                            !SPECIAL_KEYS.includes(e.key) &&
                            !e.metaKey &&
                            /\D/.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        style={{
                          borderColor: `${errors.zip_code && touched.zip_code ? '#FF3E1D' : ''
                            }`,
                          paddingRight: '0.5rem',
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-col mt-2">
                    <span className="text-xs pb-1 text-[#7E7E7E] flex items-end font-semibold">
                      States Licensed
                    </span>

                    <SelectedStatesDropDown
                      stateList={statesList}
                      selectedStates={values.licensed_states}
                      setSelectedStates={val => {
                        console.log(val)
                        setFieldValue('licensed_states', val)
                      }
                      }
                      errors={Boolean(
                        touched.licensed_states && errors.licensed_states
                      )}
                      helperText={
                        touched.licensed_states && errors.licensed_states
                      }
                      disabled={false}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-4 pt-2 pb-6 flex sm:px-6 w-full">
                <Button
                  text="Cancel"
                  // type="submit"
                  onClick={handleCloseModal}
                  className="inline-flex w-1/2 mr-3 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
                />

                <Button
                  text={'Edit User'}
                  type="submit"
                  disabled={isEditUserLoading}
                  isLoading={isEditUserLoading}
                  className={`inline-flex w-1/2 ml-3 h-46 border border-buttonLightGreen ${!isEditUserLoading &&
                    'hover:bg-white hover:text-buttonLightGreen'
                    }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default EditUser;
