import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TopNavData, ModalState, SettingsDetailsTypes, UpdateOrganizationDetailsBody, UpdateOrganizationPrivacyPolicyBody, LosConfigurations, LosConfigurationsRequestBody, GetHECMProductsBody, ProductListEntity } from "../../interface/settings.interface";
import { EditObjectType } from "@/modules/Customer/interface/customer.interface";

interface InitialState {
  topNavData: TopNavData;
  modals: ModalState;
  invokeCustomersList: boolean;
  customerId: string;
  editData: EditObjectType;
  invokeCustomerDetails: boolean;
  organizationDetails: SettingsDetailsTypes | null;
  losConfigurations: LosConfigurations | null;
  isLosConfigurationsLoading: boolean;
}

const editData: EditObjectType = {
  first_name: "",
  last_name: "",
  phone_number: "",
  dob: null,
  state: {
    id: "",
    name: "",
  },
};

const initialState: InitialState = {
  topNavData: {
    buttonText: "",
    heading: "",
  },
  modals: {
    addCustomer: false,
    addUser: false,
    resendInvite: false,
    addReport: false,
    addOrganization: false,
    userLimitReached: false,
  },
  organizationDetails: null,
  invokeCustomersList: false,
  customerId: "",
  editData,
  invokeCustomerDetails: false,
  losConfigurations: null,
  isLosConfigurationsLoading: false,
};

const setOrganizationDetails = (
  state: InitialState,
  action: PayloadAction<SettingsDetailsTypes>
) => ({
  ...state,
  organizationDetails: action.payload,
});

const updateOrganizationDetails = (
  state: InitialState,
  action: PayloadAction<UpdateOrganizationDetailsBody>
) => ({
  ...state,
});

const updateOrganizationPrivacyPolicy = (
  state: InitialState,
  action: PayloadAction<UpdateOrganizationPrivacyPolicyBody>
) => ({
  ...state,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setTopNavData = (state: InitialState, action: PayloadAction<TopNavData>) => ({
  ...state,
  topNavData: action.payload,
});

const setAddCustomerModalState = (state: InitialState, action: PayloadAction<boolean>) => ({
  ...state,
  modals: { ...state.modals, addCustomer: action.payload },
});

const setAddOrganizationModalState = (state: InitialState, action: PayloadAction<boolean>) => ({
  ...state,
  modals: { ...state.modals, addOrganization: action.payload },
});

const setAddUserModalState = (state: InitialState, action: PayloadAction<boolean>) => ({
  ...state,
  modals: { ...state.modals, addUser: action.payload },
});

const setResendInviteModalState = (state: InitialState, action: PayloadAction<boolean>) => ({
  ...state,
  modals: { ...state.modals, resendInvite: action.payload },
});

const invokeCustomersList = (state: InitialState, action: PayloadAction<boolean>) => ({
  ...state,
  invokeCustomersList: action.payload,
});

const setEditCustomer = (state: InitialState, action: PayloadAction<string>) => ({
  ...state,
  customerId: action.payload,
});

const setEditDataObject = (state: InitialState, action: PayloadAction<EditObjectType>) => ({
  ...state,
  editData: action.payload,
});

const invokeCustomerDetails = (state: InitialState, action: PayloadAction<boolean>) => ({
  ...state,
  invokeCustomerDetails: action.payload,
});

const setUserLimitReachedModalState = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  modals: { ...state.modals, userLimitReached: action.payload },
});

const getOrganizationDetails = (
  state: InitialState,
  action: PayloadAction<string | any>
) => ({
  ...state,
});

const setIsLosConfigurationsLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isLosConfigurationsLoading: action.payload,
});

const getLosConfigurations = (
  state: InitialState,
  action: PayloadAction<any>
) => ({
  ...state,
});

const setIsLosSettingsEdit = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isLosSettingsEdit: action.payload,
});

const saveOrUpdateLosConfigurations = (
  state: InitialState,
  action: PayloadAction<LosConfigurationsRequestBody>
) => ({
  ...state,
});

const getHecmProducts = (
  state: InitialState,
  action: PayloadAction<GetHECMProductsBody>
) => ({
  ...state,
});

const setHecmProducts = (
  state: InitialState,
  action: PayloadAction<ProductListEntity[]>
) => ({
  ...state,
  hecmProducts: action.payload,
});

const setLosConfigurations = (
  state: InitialState,
  action: PayloadAction<LosConfigurations>
) => ({
  ...state,
  losConfigurations: action.payload,
});

const setBreadcrumbText = (
  state: InitialState,
  action: PayloadAction<string>
) => ({
  ...state,
  breadcrumbText: action.payload,
});

const setIsEditProfileOpen = (
  state: InitialState,
  action: PayloadAction<{
    loanOfficer: boolean;
    organizationAdmin: boolean;
  }>
) => ({
  ...state,
  isEditProfileIsOpen: action.payload,
});

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setTopNavData,
    setAddCustomerModalState,
    setAddUserModalState,
    invokeCustomersList,
    setEditCustomer,
    setEditDataObject,
    invokeCustomerDetails,
    setResendInviteModalState,
    setAddOrganizationModalState,
    setUserLimitReachedModalState,
    getOrganizationDetails,
    setOrganizationDetails,
    updateOrganizationDetails,
    updateOrganizationPrivacyPolicy,
    setIsLosConfigurationsLoading,
    getLosConfigurations, setIsLosSettingsEdit,
    saveOrUpdateLosConfigurations, getHecmProducts,
    setHecmProducts, setLosConfigurations,
    setBreadcrumbText, setIsEditProfileOpen
  },
});

export default settingSlice;
