import * as Yup from 'yup';

// Regex validations
export const emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordValidation =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

// Validations using newRegex()
export const _UppercaseRegex = new RegExp('(?=.*[A-Z])');
export const _LowercaseRegex = new RegExp('(?=.*[a-z])');
export const _NumberRegex = new RegExp('(?=.*[0-9])');
export const _SpecialCharRegex = new RegExp('(?=.*[!@#$%^&*])');
export const _MinimumLengthRegex = new RegExp('(?=.{8,})');

export const NumberRegex = /^[1-9]\d*$/;
export const PriceRegex = /^\d+(\.\d{0,2})?$/;
export const NameRegex = /^[A-Za-z ]+$/;

export const isAlphanumeric = /^[A-Za-z0-9\s]*$/;
export const CharactersWithoutSpaceRegex = /^[^\s].*[^\s]$/;

// Yup validation schemas
export const OrgAdminAndLoanOfficerSubscriptionSchema = Yup.object().shape({
  admin_plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  admin_price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
  loan_officer_plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  loan_officer_price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
});

export const LoanOfficerSubscriptionSchema = Yup.object().shape({
  plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
});

export const OrgAdminSubscriptionSchema = Yup.object().shape({
  plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  user_limit: Yup.string()
    .required('User limit is required')
    .max(6, 'User limit cannot exceed 6 digits'),
  price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
});

export const organizationDetailsValidationSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required('Organization Name is required')
    .test(
      'no-whitespace',
      'Organization Name cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    ),
  nmls: Yup.string()
    .required('NMLS Number is required')
    .test(
      'no-whitespace',
      'NMLS Number cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    ),
  zip: Yup.string()
    .required('Zip Code is required')
    .test(
      'no-whitespace',
      'Zip Code cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    )
    .matches(/^\d{5}(-\d{4})?$/, 'Invalid Zip Code format'),
  phone_number: Yup.string()
    .required('Phone Number is required')
    .min(10, 'Phone number must have 10 digits')
    .max(10, 'Phone number cannot exceed 10 digits')
    .test(
      'no-whitespace',
      'Phone Number cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    ),
  address_line_1: Yup.string()
    .required('Address Line 1 is required')
    .test(
      'no-whitespace',
      'Address Line 1 cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    ),
  address_line_2: Yup.string()
    .notRequired()
    .test(
      'no-whitespace',
      'Address Line 2 cannot contain only spaces',
      (value) => !value || value.trim().length > 0
    ),
  state: Yup.string()
    .required('State is required')
    .test(
      'no-whitespace',
      'State cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    ),
});

export const organizationDetailsPrivacyPolicyValidationSchema =
  Yup.object().shape({
    privacyPolicy: Yup.string()
      .required('Privacy Policy is required')
      .max(2000, 'Privacy Policy cannot exceed 2000 characters')
      .test(
        'no-whitespace',
        'Privacy Policy cannot contain only spaces',
        (value) => !!value && value.trim().length > 0
      ),
  });


export const getHecmProductsValidationSchema = Yup.object({
  companyId: Yup.string().required('Company ID is required'),
  apiKey: Yup.string().required('API Key is required'),
});


export const addUserSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('First name is mandatory')
    .matches(
      NameRegex,
      'First name should not include any special characters or numbers'
    )
    .trim()
    .max(50, 'First name cannot exceed 50 characters'),
  last_name: Yup.string()
    .required('Last name is mandatory')
    .matches(
      NameRegex,
      'Last name should not include any special characters or numbers'
    )
    .trim()
    .max(50, 'Last name cannot exceed 50 characters'),
  email: Yup.lazy((value: string) => {
    if (value?.includes('@')) {
      return Yup.string()
        .email('Invalid email address')
        .max(255)
        .matches(emailValidation, 'Invalid email address')
        .required('Email is required');
    }
    return Yup.string()
      .required('Email is required')
      .matches(emailValidation, 'Invalid email address')
      .trim();
  }),
  nmls: Yup.string()
    .required('NMLS Number is required')
    .test(
      'no-whitespace',
      'NMLS Number cannot contain only spaces',
      (value) => !!value && value.trim().length > 0
    )
    .min(4, 'NMLS Number must be at least 4 characters')
    .max(12, 'NMLS Number cannot exceed 12 characters'),

  city: Yup.string()
    .required('City is mandatory')
    .min(2, 'City names must be 2 to 50 characters')
    .matches(CharactersWithoutSpaceRegex, 'City Name is invalid')
    .max(50, 'City names must be 2 to 50 characters'),
  address_line_1: Yup.string()
    .required('Address is mandatory')
    .min(2, 'Address should be at least 2 characters')
    .matches(CharactersWithoutSpaceRegex, 'Address is invalid')
    .max(50, 'Address cannot exceed 50 characters'),
  zip_code: Yup.string()
    .required('Zip code is mandatory')
    .matches(/^\d{5}(-\d{4})?$/, 'Invalid zip code'),
  licensed_states: Yup.array(Yup.string()).min(
    1,
    'At least one state should be selected'
  ),
});
