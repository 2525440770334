import { Dispatch, Fragment, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { AnyAction } from 'redux';
import { RootState } from '@/store/index';

import { Form, Formik } from 'formik';

import usersSlice from '../../store/slice/users.slice';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';

import Input from '@/components/Input';
import Button from '@/components/Button/Button';
import Select from '@/components/Select';
import SelectedStatesDropDown from '../Components/SelectedStatesDropDown';
import { formatZipCode } from '../../../../lib/helper';
import { addUserSchema } from '@/lib/validation';
import { InputTypes } from '@/src/enums/enums';
import { OrganizationUserBody } from '../../interface/users.interface';
import { StatesListBodyTypes } from '../../../Customer/interface/customer.interface';
import { LOAN_OFFICER, SPECIAL_KEYS } from '@/lib/constants';
import CloseIcon from '@/src/assets/images/svg/close_icon.svg';

const AddUser = ({ organization_id }: { organization_id: any }): JSX.Element => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const formikRef: any = useRef();

  const { addUser } = useAppSelector(
    (state: RootState) => state.settings.modals
  );
  const {
    usersListBody,
    userRoles,
    statesList,
    isAddUserLoading,
    isStatesListLoading,
    isUserRolesLoading,
  } = useAppSelector((state: RootState) => state.users);

  // Initial values of the form. Note that as of now the value of userRoleId is set as default as the uuid corresponding to that of Loan Officer
  const initialValues = {
    first_name: '',
    last_name: '',
    city: '',
    email: '',
    nmls: '',
    country_code: '+1',
    address_line_1: '',
    zip_code: '',
    user_role_id: '',
    state: {
      code: '',
      name: 'Select State',
      id: ''
    },
    licensed_states: [],
  };

  // Handle service api to get organization user roles
  const handleRoles = (): void => {
    dispatch(usersSlice.actions.getUserRoles());
  };

  const handleStatesList = (): void => {
    const params: StatesListBodyTypes = {
      sort: 'DESC',
      page: 0,
      limit: 60,
    };
    dispatch(usersSlice.actions.getStatesList(params));
  };

  useMemo(() => {
    if (addUser) {
      handleRoles();
      handleStatesList();
    }
  }, [addUser]);

  // Handle closing of the modal
  const handleCloseModal = (): void => {
    dispatch(settingSlice.actions.setAddUserModalState(false));
    dispatch(usersSlice.actions.setStatesList([]));
    dispatch(usersSlice.actions.setUserRoles([]));
  };

  useEffect(() => {
    if (userRoles?.length && !formikRef.current?.values.user_role_id) {
      formikRef.current?.setFieldValue(
        'user_role_id',
        userRoles?.filter(
          item =>
            item?.name?.toLocaleLowerCase()?.trim() ===
            LOAN_OFFICER.toLocaleLowerCase().trim()
        )[0]?.id
      );
    }
  }, [userRoles]);

  const enhancedStatesList = useMemo(() => {
    return [{ id: '', name: 'Select State', code: '' }, ...statesList];
  }, [statesList]);

  // Handle the create user api and to submit the values from the form
  const handleSubmit = async (values: typeof initialValues): Promise<void> => {
    if (usersListBody) {
      const addUserBody: OrganizationUserBody = {
        first_name: values.first_name.trim(),
        last_name: values.last_name.trim(),
        email: values.email.toLocaleLowerCase().trim(),
        address_line_1: values.address_line_1.trim(),
        city: values.city.trim(),
        nmls: values.nmls,
        organization_id,
        zip_code: values.zip_code,
        user_role_id: values.user_role_id,
        state_id: values.state?.id,
        licensed_states: values.licensed_states,
      };
      dispatch(
        usersSlice.actions.addUser({
          usersListBody,
          addUserBody,
        })
      );
    }
  };

  return (
    <Fragment>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={addUserSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
          setFieldValue,
        }): JSX.Element => (
          <Form className=" z-10 overflow-auto  rounded-t-lg">
            <div className="bg-white shadow-xl rounded-lg transition-all w-[443px]">
              <div className="relative rounded-t-lg bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                <div className="flex justify-end" onClick={handleCloseModal}>
                  <img src={CloseIcon} alt="" className="cursor-pointer" />
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center  sm:mt-0 sm:text-left w-full">
                    <h3
                      className="text-xl font-semibold leading-6 text-blackDarkColor"
                      id="modal-title"
                    >
                      Add User
                    </h3>

                    <div className="text-greyBlackLightColor text-xs leading-2 pt-2">
                      Invite new user under your organization
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col user-model-height overflow-auto px-4 pb-4 pt-0  sm:px-6 sm:pb-4 ">
                <div className="w-full flex">
                  <div className="w-1/2 mr-1">
                    <Input
                      placeholder="First name"
                      label="Name"
                      id="first_name"
                      name="first_name"
                      type={InputTypes.TEXT}
                      value={values.first_name}
                      labelHide={true}
                      inputIcon={false}
                      errors={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderColor: `${errors.first_name && touched.first_name
                          ? '#FF3E1D'
                          : ''
                          }`,
                      }}
                      autoFocus
                    />
                  </div>

                  <div className="w-1/2 ml-1">
                    <Input
                      placeholder="Last name"
                      label=""
                      id="last_name"
                      name="last_name"
                      type={InputTypes.TEXT}
                      value={values.last_name}
                      labelHide={true}
                      inputIcon={false}
                      errors={Boolean(touched.last_name && errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderColor: `${errors.last_name && touched.last_name ? '#FF3E1D' : ''
                          }`,
                      }}
                    />
                  </div>
                </div>

                <div className="w-full flex mt-4">
                  <Input
                    placeholder="Email"
                    label="Email"
                    id={InputTypes.EMAIL}
                    name={InputTypes.EMAIL}
                    type={InputTypes.TEXT}
                    value={values.email}
                    labelHide={true}
                    inputIcon={false}
                    errors={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{
                      borderColor: `${errors.email && touched.email ? '#FF3E1D' : ''
                        }`,
                      paddingRight: '0.5rem',
                    }}
                  />

                </div>

                <div className="w-full flex mt-2 relative ">
                  <Input
                    placeholder="NMLS Number"
                    label=" NMLS Number"
                    id="nmls"
                    name="nmls"
                    type={InputTypes.TEXT}
                    value={values.nmls}
                    inputIcon={false}
                    errors={Boolean(touched.nmls && errors.nmls)}
                    helperText={touched.nmls && errors.nmls}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={e => {
                      if (
                        !SPECIAL_KEYS.includes(e.key) &&
                        !e.metaKey &&
                        /\D/.test(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    style={{
                      borderColor: `${errors.nmls && touched.nmls ? '#FF3E1D' : ''
                        }`,
                      paddingRight: '0.5rem',
                    }}
                  />
                </div>

                <div className="w-full flex mt-5 custom-select">
                  <Select
                    name={'Role'}
                    label="Role"
                    labelHide={true}
                    options={userRoles}
                    selected={values.user_role_id}
                    //isLoading={isUserRolesLoading}
                    setSelected={(id: string) =>
                      setFieldValue('user_role_id', id)
                    }
                  />
                </div>

                <div className="flex flex-col w-full">
                  <span className="flex pt-2 pb-4 text-blackLightColor text-base font-semibold">
                    Address
                  </span>

                  <div className="w-full flex mt-1">
                    <div className="w-1/2 mr-2 relative -top-2">
                      <Input
                        placeholder="Enter the address"
                        label="Address line 1"
                        id="address_line_1"
                        name="address_line_1"
                        type={InputTypes.TEXT}
                        value={values.address_line_1}
                        colorLabel={'text-greyLightDarkColor'}
                        inputIcon={false}
                        errors={Boolean(
                          touched.address_line_1 && errors.address_line_1
                        )}
                        helperText={
                          touched.address_line_1 && errors.address_line_1
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          borderColor: `${errors.address_line_1 && touched.address_line_1
                            ? '#FF3E1D'
                            : ''
                            }`,
                          paddingRight: '0.5rem',
                        }}
                      />
                    </div>

                    <div className="w-1/2 relative -top-2">
                      <Input
                        placeholder="City"
                        label="City"
                        id="city"
                        name="city"
                        type={InputTypes.TEXT}
                        value={values.city}
                        colorLabel={'text-greyLightDarkColor'}
                        inputIcon={false}
                        errors={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{
                          borderColor: `${errors.city && touched.city ? '#FF3E1D' : ''
                            }`,
                          paddingRight: '0.5rem',
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full flex mt-2">
                    <div className="w-1/2 mr-1 custom-select">
                      <Select
                        name={'State'}
                        label="State"
                        labelHide={true}
                        options={enhancedStatesList}
                        setSelected={(name: string) => {
                          const state = enhancedStatesList.find((state) => state.name === name);
                          setFieldValue('state', state);
                        }}
                        errors={Boolean(touched.state?.id && errors.state?.id)}
                        helperText={touched?.state?.id && errors?.state?.id}
                      />
                    </div>

                    <div className="w-1/2 ml-1 relative -top-2">
                      <Input
                        placeholder="------"
                        label="Zip Code"
                        id="zip_code"
                        name="zip_code"
                        type={InputTypes.TEXT}
                        value={formatZipCode(values.zip_code)}
                        inputIcon={false}
                        errors={Boolean(touched.zip_code && errors.zip_code)}
                        helperText={touched.zip_code && errors.zip_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={e => {
                          if (
                            !SPECIAL_KEYS.includes(e.key) &&
                            !e.metaKey &&
                            /\D/.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        style={{
                          borderColor: `${errors.zip_code && touched.zip_code ? '#FF3E1D' : ''
                            }`,
                          paddingRight: '0.5rem',
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full flex flex-col mt-2">
                    <span className="text-xs pb-1 text-[#7E7E7E] flex items-end font-semibold">
                      States Licensed
                    </span>

                    <SelectedStatesDropDown
                      stateList={statesList}
                      selectedStates={values.licensed_states}
                      setSelectedStates={val =>
                        setFieldValue('licensed_states', val)
                      }
                      errors={Boolean(
                        touched.licensed_states && errors.licensed_states
                      )}
                      helperText={
                        touched.licensed_states && errors.licensed_states
                      }
                      disabled={false}
                    />
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-4 pt-2 pb-6 flex sm:px-6 w-full rounded-b-lg">
                <Button
                  text="Cancel"
                  type="submit"
                  onClick={handleCloseModal}
                  className="inline-flex w-1/2 mr-3 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
                />

                <Button
                  text={'Invite User'}
                  type="submit"
                  disabled={isAddUserLoading}
                  isLoading={isAddUserLoading}
                  className={`inline-flex w-1/2 ml-3 h-46 border border-buttonLightGreen ${!isAddUserLoading &&
                    'hover:bg-white hover:text-buttonLightGreen'
                    }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AddUser;
