import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USERS_LIST_INITIAL_VALUES } from '@/lib/constants';
import { GetUserReports, InitialState } from '../types/users.types';
import { EditOrgAdminProfileBody, EditUserBody, OrganizationUserBody, UserDetailsTypes, UserListBody, UserRoles } from '../../interface/users.interface';
import { StateListTypes, StatesListBodyTypes } from '@/modules/Customer/interface/customer.interface';
import { NavigateFunction } from 'react-router';

const initialState: InitialState = {
  usersList: USERS_LIST_INITIAL_VALUES,
  isLoading: false,
  isResendInviteModalOpen: false,
  isUserDetailsLoading: false,
  isRemoveUserModalOpen: false,
  isUserRegistrationLoading: false,
  isEditUserModalOpen: false,
  userDetails: null,
  usersListBody: null,
  isEditUserLoading: false,
  userRoles: [],
  statesList: [],
  isStatesListLoading: false,
  userRegistrationPage: '',
  isAddUserLoading: false,
  isUserRolesLoading: false,
  isRemoveUserLoading: false,
  userPerformance: null,
  isUserPerformanceLoading: false,
  inviteUser: {
    inviteUser: false,
    resendInvite: false
  }
};

const getUsersList = (
  state: InitialState,
  action: PayloadAction<UserListBody>
) => state;

const setUsersList = (
  state: InitialState,
  action: PayloadAction<InitialState['usersList']>
) => ({
  ...state,
  usersList: action.payload,
});

const setIsLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['isLoading']>
) => ({
  ...state,
  isLoading: action.payload,
});

const setUsersListBody = (
  state: InitialState,
  action: PayloadAction<InitialState['usersListBody']>
) => ({
  ...state,
  usersListBody: action.payload,
});

const getUsersLimit = (state: InitialState, action: PayloadAction<any>) =>
  state;

const setIsUserRegistrationLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['isUserRegistrationLoading']>
) => ({
  ...state,
  isUserRegistrationLoading: action.payload,
});

const setIsResendInviteModalState = (
  state: InitialState,
  action: PayloadAction<InitialState['isResendInviteModalOpen']>
) => ({
  ...state,
  isResendInviteModalOpen: action.payload,
});

const setInviteUser = (state: InitialState, action: PayloadAction<{
  inviteUser: boolean,
  resendInvite: boolean
}>) => ({
  ...state,
  inviteUser: action.payload
})

const setIsRemoveUserModalState = (
  state: InitialState,
  action: PayloadAction<InitialState['isRemoveUserModalOpen']>
) => ({
  ...state,
  isRemoveUserModalOpen: action.payload,
});

const getUserDetails = (
  state: InitialState,
  action: PayloadAction<{ userId: string }>
) => state;

const setIsEditUserModalOpen = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isEditUserModalOpen: action.payload,
});

const getUserRoles = (state: InitialState) => state;

const getStatesList = (
  state: InitialState,
  action: PayloadAction<StatesListBodyTypes>
) => state;

const setStatesList = (
  state: InitialState,
  action: PayloadAction<StateListTypes[]>
) => ({
  ...state,
  statesList: action.payload,
});

const setUserRoles = (
  state: InitialState,
  action: PayloadAction<UserRoles[]>
) => ({
  ...state,
  userRoles: action.payload,
});

const addUser = (
  state: InitialState,
  action: PayloadAction<{
    usersListBody: UserListBody;
    addUserBody: OrganizationUserBody;
  }>
) => state;

const editUser = (
  state: InitialState,
  action: PayloadAction<{
    addUserBody: EditUserBody;
    userId: string;
  }>
) => state;

const getUserPerformance = (
  state: InitialState,
  action: PayloadAction<GetUserReports>
) => state;

const setIsRemoveUserLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['isRemoveUserLoading']>
) => ({
  ...state,
  isRemoveUserLoading: action.payload,
});

const setUserRegistrationPage = (
  state: InitialState,
  action: PayloadAction<InitialState['userRegistrationPage']>
) => ({
  ...state,
  userRegistrationPage: action.payload,
});

const setIsUserDetailsLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isUserDetailsLoading: action.payload,
});

const setUserDetails = (
  state: InitialState,
  action: PayloadAction<UserDetailsTypes | null>
) => ({
  ...state,
  userDetails: action.payload,
});

const setIsEditUserLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isEditUserLoading: action.payload,
});

const setIsEditOrganizationAdminProfileLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isEditOrganizationAdminProfileLoading: action.payload,
});

const removeUser = (
  state: InitialState,
  action: PayloadAction<{
    userId: string;
    usersListBody: UserListBody | null;
    navigateFunction: () => void;
  }>
) => state;

const saveContactDetails = (
  state: InitialState,
  action: PayloadAction<{
    country_code: string;
    phone_number: string;
  }>
) => state;

const saveOrganizationDetails = (
  state: InitialState,
  action: PayloadAction<{
    body: {
      nmls: string;
      policy: string;
      report_id: string;
    };
    navigate: NavigateFunction;
  }>
) => state;

const setIsUserPerformanceLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['isUserPerformanceLoading']>
) => ({
  ...state,
  isUserPerformanceLoading: action.payload,
});

const setUserPerformance = (
  state: InitialState,
  action: PayloadAction<InitialState['userPerformance']>
) => ({
  ...state,
  userPerformance: action.payload,
});

const setIsUsersLimitReached = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isUsersLimitReached: action.payload,
});

const setIsUserRolesLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isUserRolesLoading: action.payload,
});

const setIsStatesListLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isStatesListLoading: action.payload,
});

const setIsAddUserLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isAddUserLoading: action.payload,
});

const editOrganizationAdminProfile = (
  state: InitialState,
  action: PayloadAction<EditOrgAdminProfileBody>
) => state;

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersList,
    setUsersList,
    getUsersLimit,
    setIsLoading,
    setUsersListBody,
    setIsResendInviteModalState,
    setInviteUser,
    setIsRemoveUserModalState,
    getUserDetails,
    setIsEditUserModalOpen,
    getUserRoles,
    getStatesList,
    setStatesList,
    setUserRoles, addUser,
    editUser, getUserPerformance,
    setIsRemoveUserLoading,
    setIsUserRegistrationLoading,
    setUserRegistrationPage,
    setIsUserDetailsLoading,
    setUserDetails, setIsEditUserLoading,
    setIsEditOrganizationAdminProfileLoading,
    removeUser, saveContactDetails,
    saveOrganizationDetails, setIsUserPerformanceLoading,
    setUserPerformance, setIsUsersLimitReached,
    setIsUserRolesLoading, setIsStatesListLoading,
    setIsAddUserLoading, editOrganizationAdminProfile,
  },
});

export default usersSlice;
