import { useAppSelector } from "@/hooks/useRedux";
import CloseIcon from "../../../../assets/images/svg/close_icon.svg";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { RemoveUserService } from "../../service/users.service";
import { useNavigate } from "react-router";
import usersSlice from "../../store/slice/users.slice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ORGANIZATIONS_DETAILS } from "@/src/routes/routesConstants";
import Spinner from "@/components/Spinner";

const RemoveUser = ({
  removeUserDetails, org_id, organization_name, handleRemoveUserModal
}: {
  removeUserDetails: { id: string; first_name: string; last_name: string; }, org_id: string; organization_name: string, handleRemoveUserModal: (isOpen: boolean) => void
}): JSX.Element => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const handleRemoveUser = async () => {

    try {
      setIsLoading(true)
      const response = await RemoveUserService(removeUserDetails.id);
      navigate(`${ORGANIZATIONS_DETAILS}/users?org_id=${org_id}&organization_name=${organization_name}`)
      dispatch(usersSlice.actions.setIsRemoveUserModalState(false))
      setIsLoading(false)
      return response;
    } catch (err) {
      toast.error((err as AxiosError).message, { theme: 'colored' });
    }

  };

  return (
    <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[348px] font-Inter">
      <div className=" p-6 ">
        <div className="mt-3 text-left">
          <img
            onClick={() => { handleRemoveUserModal(false) }}
            src={CloseIcon}
            alt="*"
            className="cursor-pointer absolute right-5 top-8"

          />
          <h3 className="text-xl font-semibold leading-6 text-blackDarkColor" id="modal-title">
            Remove User
          </h3>
          <div className="mt-4">
            <p className="text-sm font-medium text-greyBlackLightColor leading-normal">
              User will be removed from the organization and the invite link will be no longer valid
            </p>
          </div>
        </div>
      </div>
      <div className=" pt-3 pb-6 flex px-6 w-full">
        <button
          type="button"
          onClick={() => { handleRemoveUserModal(false) }}
          className="inline-flex justify-center items-center rounded h-9 border border-greyLightColor text-sm font-medium text-interBlack w-1/2"
        >
          Cancel
        </button>
        <button
          onClick={handleRemoveUser}
          type="button"
          className="inline-flex justify-center items-center h-9 rounded bg-redButtonColor text-sm font-medium text-white ml-3 w-1/2 hover:opacity-50"
        >
          {isLoading ? <Spinner /> : "Remove User"}
        </button>
      </div>
    </div>
  )
}

export default RemoveUser
