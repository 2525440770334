import React, { useState, useEffect, useRef } from 'react';
import { StateListTypes } from '../../../Customer/interface/customer.interface';

import SelectDownIcon from '@/src/assets/imagesComponents/SelectDownIcon';
import SmallCloseIcon from '@/src/assets/imagesComponents/SmallCloseIcon';
import { searchFilter } from '@/src/util/searchFilter';

const SelectedStatesDropDown = ({
  stateList,
  selectedStates,
  setSelectedStates,
  errors,
  helperText,
  disabled,
}: {
  stateList: StateListTypes[];
  selectedStates: string[];
  setSelectedStates: (value: string[]) => void;
  errors: boolean;
  helperText: any;
  disabled: boolean;
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectAll = () => {
    if (allOptionsSelected) {
      setSelectedStates([]);
    } else {
      setSelectedStates(stateList.map(state => state.id));
    }
  };

  const allOptionsSelected = React.useMemo(
    () => stateList.length === selectedStates.length,
    [stateList, selectedStates]
  );

  const handleOptionClick = (option: { id: any; name?: string }) => {
    const indexOfItem = selectedStates.indexOf(option.id);
    if (indexOfItem !== -1) {
      setSelectedStates(selectedStates.filter(item => item !== option.id));
    } else {
      setSelectedStates([...selectedStates, option.id]);
    }
  };

  return (
    <div className="flex flex-col w-full relative">
      <div className="relative w-full" ref={dropdownRef}>
        <div
          className="relative w-full"
          onClick={() => {
            if (!disabled) toggleDropdown();
          }}
        >
          <input
            className={`${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              } border ${isOpen ? 'border-buttonLightGreen' : ''} ${errors ? 'border-redErrorColor' : 'border-greyLightColor'
              } bg-white rounded py-2 pl-3 pr-9 w-full text-xs h-9 focus:outline-none placeholder:text-xs placeholder:text-greyThinLightColor`}
            type="text"
            placeholder="Select licensed states"
            disabled={disabled}
            onClick={() => {
              if (!disabled) toggleDropdown();
            }}
            onChange={e => setSearchText(e.target.value)}
          />

          <div
            className={`absolute top-[3px] right-[3px] mt-[-1px] h-8 w-[41px] rounded ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
          >
            <div
              className={`h-full w-full flex justify-center items-center ${isOpen ? 'rotate-180 transition-all' : ''
                }`}
            >
              <SelectDownIcon />
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="absolute top-full left-0 w-full bg-white rounded-md shadow-card dropdown-states text-blackLightColor h-28 overflow-y-auto pt-4">
            <div className="flex items-center px-3">
              <input
                type="checkbox"
                className="custom-checkbox"
                id="checkboxSelectAll"
                checked={allOptionsSelected}
                onChange={handleSelectAll}
                readOnly
              />
              <label
                htmlFor="checkboxSelectAll"
                className="ml-2 text-xs cursor-pointer w-full h-[33px]"
              >
                Select All
              </label>
            </div>

            {stateList?.length ? (
              stateList.map(option =>
                searchFilter(option.name, searchText) ? (
                  <div
                    key={option.id}
                    className="flex items-center justify-center px-3  last:pb-2"
                  >
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      id={`checkbox${option.id}`}
                      checked={selectedStates.includes(option.id)}
                      readOnly
                      onChange={() => handleOptionClick(option)}
                    />
                    <label
                      htmlFor={`checkbox${option.id}`}
                      className="ml-2 text-xs cursor-pointer w-full h-[33px] "
                    >
                      {option.name}
                    </label>
                  </div>
                ) : null
              )
            ) : (
              <div>Loading states list...</div>
            )}
          </div>
        )}
      </div>

      {errors && (
        <p className="text-redErrorColor absolute top-[37px] font-normal text-small font-Inter mt-0.287">
          {helperText}
        </p>
      )}

      <div className="flex flex-wrap mt-2 max-h-16 overflow-y-auto">
        {stateList.length > 1 && (
          <>
            {stateList.map((option, index) =>
              selectedStates.includes(option.id) ? (
                <div
                  key={index}
                  className={`flex items-center bg-gray-200 rounded-[5px] px-2 h-5 mr-2 mt-2 ${disabled
                      ? 'cursor-not-allowed opacity-50'
                      : 'cursor-pointer'
                    } `}
                  onClick={() => {
                    if (!disabled) handleOptionClick(option);
                  }}
                >
                  <span className="text-small text-gray-600">
                    {option.name}
                  </span>
                  <span
                    className={`pl-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                      }`}
                    onClick={() => {
                      if (!disabled) handleOptionClick(option);
                    }}
                  >
                    <SmallCloseIcon />
                  </span>
                </div>
              ) : null
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SelectedStatesDropDown;
