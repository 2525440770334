import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UserDetailsLoading = (): JSX.Element => {
  return (
    <>
      <div className="flex flex-col p-7 w-1/2">
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={100} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={100} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={100} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={100} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={100} height={16} />
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-7 w-3/4">
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={220} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={220} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={220} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={220} height={16} />
            </span>
          </div>
        </div>
        <div className="flex w-full text-xs font-medium text-greyBlackLightColor pt-3">
          <div className="w-1/2 md:w-1/4">
            <span>
              <Skeleton count={1} width={60} height={16} />
            </span>
          </div>
          <div className="ml-3 w-1/2 md:w-9/12">
            <span className="text-greyBlackColor">
              <Skeleton count={1} width={220} height={16} />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailsLoading;
