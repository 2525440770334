import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '@/components/Button';
import Input from '@/components/Input';
import CloseIcon from '@/src/assets/images/svg/close_icon.svg';
import AutoCompleteInput from '@/components/AutoCompleteInput';
import ErrorInputText from '@/components/ErrorInputText/ErrorInputText';
import { useAppSelector } from '@/hooks/useRedux';
import { useDispatch } from 'react-redux';
import settingSlice from '../../store/slice/settings.slice';
import useLoading from '@/hooks/useLoading';
import { organizationDetailsValidationSchema } from '@/lib/validation';

interface Props {
  handleClose: () => void;
  organization_id: string
}

const EditOrganization: React.FC<Props> = ({ handleClose, organization_id }) => {
  const isLoading = useLoading(
    settingSlice.actions.updateOrganizationDetails.type
  );
  const dispatch = useDispatch();
  const settingsDetails = useAppSelector(
    state => state.settings.organizationDetails
  );
  const initialValues = {
    organization_name: '',
    nmls: '',
    country_code: '1',
    phone_number: '',
    zip: '',
    address_line_1: '',
    address_line_2: '',
    state: '',
    pdf_footer_policy: 'text policy',
    pdf_header_text_color: '#1111',
  };

  const onSubmit = (values: any) => {
    dispatch(settingSlice.actions.updateOrganizationDetails({ ...values, organization_id }));
    return
  };

  const formik = useFormik({
    initialValues,
    validationSchema: organizationDetailsValidationSchema,
    onSubmit,
  });

  const { errors, touched, values, setFieldValue } = formik;

  useEffect(() => {
    if (settingsDetails) {
      const { nmls, phone_number, address_line_1, address_line_2, state, zip } =
        settingsDetails.organization_settings;
      const { name } = settingsDetails;
      // Set the field values using setFieldValue
      formik.setFieldValue('organization_name', name || '');
      formik.setFieldValue('nmls', nmls || '');
      formik.setFieldValue('phone_number', phone_number || '');
      formik.setFieldValue('address_line_1', address_line_1 || '');
      formik.setFieldValue('address_line_2', address_line_2 || '');
      formik.setFieldValue('state', state || '');
      formik.setFieldValue('zip', zip || '');
    }
  }, [settingsDetails]);

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Allow only numbers
    const value = event.target.value.replace(/[^\d]/g, '');
    setFieldValue('phone_number', value);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="relative transform  bg-white rounded-lg shadow-xl transition-all w-[531px] font-Inter">
        <div className="absolute right-6 top-7" onClick={handleClose}>
          <img src={CloseIcon} alt="" className="cursor-pointer" />
        </div>
        <div className="p-6">
          <div className="text-left">
            <h3
              className="text-xl font-semibold leading-6 text-blackDarkColor"
              id="modal-title"
            >
              Edit Organization Details
            </h3>
            <div className="mt-2">
              <p className="text-xs font-medium text-greyBlackLightColor leading-normal">
                Please add/edit the details of the organization.
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-5 ">
            {/* Organization Name */}
            <div className="w-full mr-1">
              <Input
                placeholder="Organization Name"
                label="Organization Name"
                id="organization_name"
                name="organization_name"
                type="text"
                errors={Boolean(
                  formik.touched.organization_name &&
                  formik.errors.organization_name
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.organization_name}
              />
              {formik.touched.organization_name &&
                formik.errors.organization_name && (
                  <div className="text-red-600 text-small">
                    {formik.errors.organization_name}
                  </div>
                )}
            </div>

            {/* NMLS Number */}
            <div className="w-full mt-4">
              <Input
                placeholder="NMLS Number"
                label="NMLS"
                id="nmls"
                name="nmls"
                type={'text'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nmls}
                disabled={true}
              />
              {formik.touched.nmls && formik.errors.nmls && (
                <div className="text-red-600 text-small">
                  {formik.errors.nmls}
                </div>
              )}
            </div>

            {/* Phone Number */}
            <div className="w-full flex-col flex mt-4">
              <div className="flex w-full">
                <div className="w-1/5 mr-1">
                  <Input
                    placeholder="US : +1"
                    label="Phone"
                    id="country_code"
                    name="country_code"
                    type="text"
                    value="US: +1"
                    disabled
                  />
                </div>

                <div className="w-4/5 ml-1">
                  <Input
                    placeholder="(XXX) XXX-XXX"
                    label=""
                    id="phone_number"
                    name="phone_number"
                    type={'text'}
                    errors={Boolean(
                      formik.touched.phone_number && formik.errors.phone_number
                    )}
                    onChange={handlePhoneNumberChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_number}
                  />
                  {formik.touched.phone_number &&
                    formik.errors.phone_number && (
                      <div className="text-red-600 text-small ">
                        {formik.errors.phone_number}
                      </div>
                    )}
                </div>
              </div>
            </div>

            {/* ZIP code */}
            <div className="w-full mt-4">
              <Input
                placeholder="zip code"
                label="zip code"
                id="zip"
                name="zip"
                type={'text'}
                errors={Boolean(
                  formik.touched.zip && formik.errors.zip
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.zip}
              />
              {formik.touched.zip &&
                formik.errors.zip && (
                  <div className="text-red-600 text-small">
                    {formik.errors.zip}
                  </div>
                )}
            </div>

            {/* Address Line 1 */}
            <div className="w-full mt-4">
              <Input
                placeholder="Address Line 1"
                label="Address Line 1"
                id="address_line_1"
                name="address_line_1"
                type={'text'}
                errors={Boolean(
                  formik.touched.address_line_1 && formik.errors.address_line_1
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_line_1}
              />
              {formik.touched.address_line_1 &&
                formik.errors.address_line_1 && (
                  <div className="text-red-600 text-small">
                    {formik.errors.address_line_1}
                  </div>
                )}
            </div>

            {/* Address Line 2 */}
            <div className="w-full mt-4">
              <Input
                placeholder="Address Line 2"
                label="Address Line 2"
                id="address_line_2"
                name="address_line_2"
                type={'text'}
                errors={Boolean(
                  formik.touched.address_line_2 && formik.errors.address_line_2
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_line_2}
              />
              {errors.address_line_2 && touched.address_line_2 && (
                <ErrorInputText errorMsg={errors.address_line_2} />
              )}
            </div>

            <div className="w-full flex-col flex mt-4">
              <div className="text-greyLightDarkColor text-xs font-semibold h-6">
                State
              </div>
              <div className="w-full ">
                <AutoCompleteInput
                  allStates={true}
                  errorInput={
                    errors.state && touched.state
                      ? 'auto-complete-error auto-complete-box'
                      : 'auto-complete-box'
                  }
                  selectedState={values?.state}
                  setSelectedState={(val: { id: string; name: string }) => {
                    setFieldValue('state', val.name);
                  }}
                  setTouched={() => formik.setTouched({ state: true })}
                />
                {errors.state && touched.state && (
                  <ErrorInputText errorMsg={errors.state} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-6 flex justify-end items-end px-6 w-full h-[100px] ">
          <Button
            text="Cancel"
            type="button"
            onClick={handleClose}
            className="inline-flex w-1/2 mr-3 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
          />
          <Button
            text="Save"
            type="submit"
            isLoading={isLoading}
            className={`inline-flex w-1/2 ml-3 h-46 border border-buttonLightGreen ${!isLoading && 'hover:bg-white hover:text-buttonLightGreen'
              }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded`}
          />
        </div>
      </div>
    </form>
  );
};

export default EditOrganization;
