export enum InputTypes {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  NUMBER = 'number',
  RADIO = 'radio',
  FILE = 'file'
}

export enum newPasswordCriteria {
  UPPERCASE = 'uppercase',
  LOWERCASE = 'lowercase',
  NUMBER = 'number',
  SPECIAL_CHARACTERS = 'specialChar',
  MINIMUM_LENGTH = 'length',
}

export enum PendingPage {
  ADD_CONTACT_DETAILS = 'phone_number',
  ADD_LOGO = 'organization_logo',
  ADD_ORGANIZATION_DETAILS = 'organization_details',
  ADD_SUBSCRIPTION_PLANS = 'organization_subscription',
  PHONE_NUMBER_OR_NMLS = 'phone_number_or_nmls',

}

export enum SubscriptionTypeEnums {
  ORGANIZATION_ADMIN = 'ORG_ADMIN_ONLY',
  LOAN_OFFICER = 'LOAN_OFFICER_ONLY',
  ORGANIZATION_ADMIN_AND_LOAN_OFFICER = 'ORG_ADMIN_LOAN_OFFICER',
}

export enum PaymentTypes {
  ORG_ADMIN_LOAN_OFFICER = 'ORG_ADMIN_LOAN_OFFICER',
  ORD_ADMIN = 'ORG_ADMIN_ONLY',
  LOAN_OFFICER = 'LOAN_OFFICER_ONLY',
}
