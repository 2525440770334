import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PerformanceChartLoader = () => {
  return (
    <div className=" p-6 ">
      <Skeleton height={422} />
    </div>
  );
};

export default PerformanceChartLoader;
